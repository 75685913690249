import { Fragment } from 'react';
import { BarLoader } from "react-spinners";
import { selectApp } from '../redux/app.reducer';
import { useAppSelector } from '../redux/hooks';

const InitialLoading = () => {
  return (
    <Fragment>
      <div className={`d-flex align-items-center justify-content-center`}
        style={{ height: "100vh" }}>
        <div className="d-flex flex-column align-items-center">
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.svg`}
            alt="loading"
            width={120}
            height={120}
            style={{ marginBottom: 16 }}
          />
          <BarLoader
            color="#1fb764"
            height={4}
            width={200}
            css="border-radius: 4px;"
          />
        </div>
      </div>
    </Fragment>

  );
};

const PageLoading = () => {
  const { loading } = useAppSelector(selectApp);
  if (loading)
    return (
      <Fragment>
        <div className={`d-flex align-items-center justify-content-center`}
          style={{
            height: "100%",
            width: "100%",
            background: "#a5a5a53b",
            zIndex: "10000000",
            position: "fixed",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "50%",
          }}>
          <div className="d-flex flex-column align-items-center">
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.svg`}
              alt="loading"
              width={120}
              height={120}
              style={{ marginBottom: 16 }}
            />
            <BarLoader
              color="#1fb764"
              height={4}
              width={200}
              css="border-radius: 4px;"
            />
          </div>
        </div>
      </Fragment>
    );
  return <></>
};

export {
  InitialLoading,
  PageLoading
};
