import { useEffect, useState } from "react";
import { getInformationApi, updateInformationApi } from "../../api/core";
import { IInformation } from "../../interface/information";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";

const $ = require("jquery");
$.dropify = require("dropify");

const SettingInformation = () => {
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [information, setInformation] = useState<IInformation>(
    {} as IInformation
  );
  const [loading, setLoading] = useState(false);

  const initDropify = (data?: IInformation) => {
    const logoEvent = $('input[name="logo"]').dropify({
      defaultFile: !!data?.logo
        ? `${process.env.REACT_APP_IMAGE_HOST}/${data.logo}`
        : "",
    });
    logoEvent.on("dropify.afterClear", function (event, element) {
      setInformation((prev) => ({
        ...prev,
        logo: "",
      }));
    });

    const qrCodeEvent = $('input[name="qrcode"]').dropify({
      defaultFile: !!data?.qrCode
        ? `${process.env.REACT_APP_IMAGE_HOST}/${data.qrCode}`
        : "",
    });
    qrCodeEvent.on("dropify.afterClear", function (event, element) {
      setInformation((prev) => ({
        ...prev,
        qrCode: "",
      }));
    });
  };

  const getInformation = async () => {
    try {
      setLoading(true);

      const { data } = await getInformationApi();
      setInformation(data);

      initDropify(data);

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const updateInformation = async (payload: IInformation) => {
    try {
      setLoading(true);

      const { data } = await updateInformationApi(payload);
      setInformation(data);

      setLoading(false);

      openSnackbar("Successfuly");
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    getInformation();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                updateInformation(information);
              }}
            >
              <fieldset>
                <legend>ข้อมูลทั่วไป</legend>
                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label font-weight-bold"
                  >
                    ชื่อบริษัท
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ชื่อบริษัท"
                      value={information.name}
                      onChange={(e) => {
                        setInformation((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label font-weight-bold"
                  >
                    โลโก้บริษัท
                  </label>
                  <div className="col-sm-4">
                    <input
                      name="logo"
                      type="file"
                      onChange={(e) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(e.target.files![0]);
                        reader.onload = function () {
                          setInformation((prev) => ({
                            ...prev,
                            logo: reader.result as string,
                          }));
                        };
                      }}
                      className="dropify"
                      data-max-file-size="3M"
                      data-allowed-file-extensions="jpg png jpeg"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label font-weight-bold"
                  >
                    ที่อยู่
                  </label>
                  <div className="col-sm-4">
                    <textarea
                      className="form-control"
                      placeholder="ที่อยู่บริษัท"
                      style={{
                        maxWidth: "100%",
                        minHeight: 150,
                      }}
                      value={information.address}
                      onChange={(e) => {
                        setInformation((prev) => ({
                          ...prev,
                          address: e.target.value,
                        }));
                      }}
                    ></textarea>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label font-weight-bold"
                  >
                    โทรศัพท์
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="โทรศัพท์"
                      value={information.phoneNumber}
                      onChange={(e) => {
                        setInformation((prev) => ({
                          ...prev,
                          phoneNumber: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label font-weight-bold"
                  >
                    อีเมล
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="อีเมล"
                      value={information.email}
                      onChange={(e) => {
                        setInformation((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label font-weight-bold"
                  >
                    เลขประจำตัวผู้เสียภาษี
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="เลขประจำตัวผู้เสียภาษี"
                      value={information.taxNumber}
                      onChange={(e) => {
                        setInformation((prev) => ({
                          ...prev,
                          taxNumber: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>ข้อมูลธนาคาร</legend>
                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label font-weight-bold"
                  >
                    ธนาคาร
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ธนาคาร"
                      value={information.bankName}
                      onChange={(e) => {
                        setInformation((prev) => ({
                          ...prev,
                          bankName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label font-weight-bold"
                  >
                    ชื่อบัญชี
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ชื่อบัญชี"
                      value={information.accountName}
                      onChange={(e) => {
                        setInformation((prev) => ({
                          ...prev,
                          accountName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label font-weight-bold"
                  >
                    เลขบัญชี
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="เลขบัญชี"
                      value={information.accountNumber}
                      onChange={(e) => {
                        setInformation((prev) => ({
                          ...prev,
                          accountNumber: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </fieldset>

              <div className="form-group row">
                <label
                  htmlFor=""
                  className="col-sm-3 col-form-label font-weight-bold"
                >
                  QRCode
                </label>
                <div className="col-sm-4">
                  <input
                    type="file"
                    onChange={(e) => {
                      let reader = new FileReader();
                      reader.readAsDataURL(e.target.files![0]);
                      reader.onload = function () {
                        setInformation((prev) => ({
                          ...prev,
                          qrCode: reader.result as string,
                        }));
                      };
                    }}
                    name="qrcode"
                    className="dropify"
                    data-max-file-size="3M"
                    data-allowed-file-extensions="jpg png jpeg"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-4 text-right">
                  <button className="btn btn-primary" type="submit">
                    บันทึก
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingInformation;
