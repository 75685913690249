import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from 'react-simple-snackbar'
import Datatable, { DatatableElement } from '../../component/datatable';
import Meta from '../../interface/meta';
import { setAppLoading } from '../../redux/app.reducer';
import { useAppDispatch } from '../../redux/hooks';
import Pagination from '../../component/pagination';
import Breadcrumb from '../../component/breadcrumb';
import { INews } from '../../interface/news';
import { deleteNewsByIdApi, filterNewsApi } from '../../api/news';
import ModalViewImage from '../../component/modal-view-image';
import { createRoot } from 'react-dom/client';
import moment from 'moment';
import { useRef } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { getSrcImage } from '../../utils/utils';

const NewsList = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState<INews[]>([]);
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const [showDetail, setShowDetail] = useState({ show: false, detail: "" });
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });

  const table = useRef<DatatableElement>(null);
  const action = useRef<HTMLSelectElement>(null);
  const keyword = useRef<HTMLInputElement>(null);

  const filterNews = async (page: number, limit: number) => {
    try {
      const { data } = await filterNewsApi({ keyword: keyword.current?.value, page, limit });

      setNews(data.results ?? [])
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const handleAction = async () => {
    try {
      if (action.current?.value === "") return

      const selected: any[] = table.current?.selected();
      if (selected.length === 0) return
      setLoading(true);

      switch (action.current?.value) {
        case "delete": {
          await Promise.all(selected.map(e => deleteNewsByIdApi(e.id)))
          await filterNews((selected.length === meta.limit) && meta.page > 1 ? meta.page - 1 : meta.page, meta.limit);
          break;
        }

        default:
          break;
      }

      openSnackbar("Successfuly");
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    filterNews(1, meta.limit);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])


  return (
    <>
      <Breadcrumb
        title={"ประกาศข่าว"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "ประกาศข่าว" }
        ]}
      />

      <div className="row">
        <div className="col-12 mb-2">
          <div className="d-flex justify-content-between align-items-center flex-row mb-2">
            <h3 className="mt-4 ml-1">ทั้งหมด {meta.totalCount.toLocaleString('en-US')} รายการ</h3>
            <div className='d-flex flex-row align-items-end' style={{ gap: "1rem" }}>
              <div>
                <span>ค้นหา</span>
                <input
                  type="text"
                  className="form-control global_filter"
                  ref={keyword}
                  placeholder="รายละเอียด"
                  onChange={() => {
                    _.debounce(() => {
                      filterNews(1, meta.limit)
                    }, 200)();
                  }}
                />
              </div>

              <div style={{ width: 120 }}>
                <span>ดำเนินการ</span>
                <select className="form-control" ref={action}>
                  <option value="">เลือก</option>
                  <option value="delete">ลบ</option>
                </select>
              </div>

              <div>
                <button onClick={() => {
                  handleAction()
                }} type="button" className="btn btn-primary">ดำเนินการ</button>
              </div>
              <div>
                <button onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/news/add`)
                }} type="button" className="btn btn-primary">+ เพิ่มข้อมูล</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <Datatable
                ref={table}
                data={news}
                columns={[
                  {
                    isCheckbox: true,
                    title: "",
                  },
                  {
                    title: "วันที่ส่ง",
                    data: "effectiveDate",
                    width: 130,
                    render: (data: number) => {
                      return moment.unix(data).format("yyyy-MM-DD HH:mm");
                    },
                  },
                  {
                    title: "หัวข้อ",
                    data: "title",
                    width: 220,
                    render(data: string, type, row, meta) {
                      return `<div style="width:220px" class="line-clamp-2">${data}</div>`
                    },
                  },
                  {
                    title: "รายละเอียด",
                    data: null,
                    width: 100,
                    createdCell(cell, cellData, rowData: INews) {
                      const cb = (detail) => {
                        setShowDetail({
                          detail: detail,
                          show: true,
                        })
                      }
                      createRoot(cell).render(
                        <button onClick={() => cb(rowData.description)} className="btn btn-primary btn-sm">รายละเอียด</button>
                      )
                    }
                  },
                  {
                    title: "รูปแนบ",
                    data: null,
                    createdCell(cell, cellData, rowData: INews) {
                      const cb = (src) => {
                        setShowImage({
                          src: src,
                          show: true,
                        })
                      }
                      createRoot(cell).render(
                        <div className="cursor-pointer img-thumbmail" onClick={() => {
                          cb(getSrcImage(rowData.cover))
                        }}>
                          <img alt="" src={getSrcImage(rowData.cover)} />
                        </div>
                      )
                    },
                  },
                  {
                    title: "สถานะ",
                    data: "status",
                    render: (data: number) => {
                      switch (data) {
                        case 0:
                          return "<span class='badge badge-light'>ยกเลิก</span>"
                        case 1:
                          return "<span class='badge badge-success'>ส่งแล้ว</span>"
                        case 2:
                          return "<span class='badge badge-warning'>รอส่ง</span>"
                        default:
                          return ""
                      }
                    },
                  },
                  {
                    title: "วันที่สร้าง",
                    data: "createdAt",
                    width: 100,
                    render: (data: string) => {
                      return moment(data).format("yyyy-MM-DD HH:mm:ss");
                    },
                  },
                  {
                    title: "หมายเหตุ",
                    data: null,
                    width: 120,
                    render(data, type, row: INews, meta) {
                      return `<div style="width:120px" class="line-clamp-2">${row.note}</div>`
                    },
                  },
                ]}
                actions={[
                  {
                    button: {
                      callback(data: INews) {
                        navigate(`${process.env.PUBLIC_URL}/news/edit/${data.id}`)
                      },
                      code: <button className="btn btn-primary btn-sm">แก้ไข</button>
                    },
                  },
                ]}
              />

              <Pagination
                active={meta.page}
                limit={meta.limit}
                onPageChange={(page) => {
                  setLoading(true);
                  filterNews(page, meta.limit);
                }}
                pageRangeDisplayed={5}
                total={meta.totalCount}
              />
            </div>
          </div>
        </div>
        <ModalViewImage
          open={showImage.show}
          src={showImage.src}
          toggle={() => {
            setShowImage(prev => ({
              ...prev,
              show: false,
            }))
          }}
        />

        <Modal size="lg" isOpen={showDetail.show} toggle={() => setShowDetail({ show: false, detail: "" })} centered={false} style={{ maxWidth: 800 }}>
          <div className="modal-title modal-header">
            <h5 className="modal-title">รายละเอียด</h5>
            <button type="button" onClick={() => setShowDetail({ show: false, detail: "" })} className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: showDetail.detail }}></div>
          </ModalBody>
        </Modal>
      </div>
    </>
  )
}
export default NewsList;
