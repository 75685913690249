import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getBannerApi, updateBannerApi } from "../../api/core";
import Breadcrumb from "../../component/breadcrumb";
import { IBanner } from "../../interface/banner";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { getSrcImage } from "../../utils/utils";

const $ = require('jquery');
$.dropify = require("dropify");

const Banner = () => {
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState<string>();
  const maxWidth = 700;
  const maxHeight = 200;
  const initDropify = (defaultFile?: string) => {
    const drEvent = $('.dropify').dropify({
      defaultFile: !!defaultFile ? getSrcImage(defaultFile) : "",
      error: {
        'minWidth': `จำกัดขนาดความกว้างของรูปที่ ${maxWidth}px`,
        'maxWidth': `จำกัดขนาดความกว้างของรูปที่ ${maxWidth}px`,
        'minHeight': `จำกัดขนาดความสูงของรูปที่ ${maxHeight}px`,
        'maxHeight': `จำกัดขนาดความสูงของรูปที่ ${maxHeight}px`,
      }
    });
    drEvent.on('dropify.afterClear', function (event, element) {
      setBanner("");
    });
  }

  const getBanner = async () => {
    try {
      setLoading(true);

      const { data } = await getBannerApi();
      setBanner(data.picture)

      initDropify(data.picture)

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const updateBanner = async (payload: IBanner) => {
    try {
      setLoading(true);

      const { data } = await updateBannerApi(payload);
      setBanner(data.picture)

      setLoading(false);

      openSnackbar("Successfuly");
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    getBanner();
    // eslint-disable-next-line
  }, [])


  return (
    <>
      <Breadcrumb
        title={"Banner"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "Banner" }
        ]}
      />

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills  active" id="pills-tab" role="tablist">
            <li className="nav-item mt-2">
              <span className="nav-link active">Banner</span>
              {/* <Link className="nav-link" to="/banners">Banner</Link> */}
            </li>
            <li className="nav-item mt-2">
              {/* <span className="nav-link active">Footer</span> */}
              <Link className="nav-link" to="/banners/footer">Footer</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-8 mb-4">
                <input
                  type="file"
                  onChange={(e) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(e.target.files![0]);
                    reader.onload = function () {
                      setBanner(reader.result as string)
                    };
                  }}
                  className="dropify"
                  data-max-file-size="3M"
                  data-allowed-file-extensions="jpg png jpeg"
                  data-height="400"
                  data-max-height={maxHeight + 1}
                  data-min-height={maxHeight - 1}
                  data-max-width={maxWidth + 1}
                  data-min-width={maxWidth - 1}
                />
                <div className="text-danger text-small">* จำกัดขนาดความกว้างของรูปที่ {maxWidth}px</div>
                <div className="text-danger text-small">* จำกัดขนาดความสูงของรูปที่ {maxHeight}px</div>
              </div>
              <div className="col-12 border-top pt-4">
                <div className="row">
                  <button className="btn btn-primary ml-auto mr-2" onClick={() => {
                    updateBanner({ picture: banner ?? "" })
                  }}>บันทึก</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Banner;
