import FileSaver from "file-saver";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  confirmOrderUpgradeApi,
  filterOrderUpgradeApi,
  getOrderUpgradeSummaryApi,
} from "../../api/order_upgrade";
import Breadcrumb from "../../component/breadcrumb";
import ModalViewImage from "../../component/modal-view-image";
import Pagination from "../../component/pagination";
import Meta from "../../interface/meta";
import {
  IFilterOrderUpgrade,
  IOrderUpgradeSummary,
} from "../../interface/order_upgrade";
import { selectApp, setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { getSrcImage } from "../../utils/utils";

const ProductOrder = () => {
  const { pathname } = useLocation();
  const { badge } = useAppSelector(selectApp);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<IFilterOrderUpgrade[]>([]);
  const [summary, setSummary] = useState<IOrderUpgradeSummary>();
  const [meta, setMeta] = useState<Meta>({
    page: 1,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [filter, setFilter] = useState({
    begin: 0,
    end: 0,
    orderNo: "",
    userName: "",
    status: "",
  });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const getOrderUpgradeSummary = async () => {
    try {
      const { data } = await getOrderUpgradeSummaryApi();
      setSummary(data);
    } catch (e: any) {
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const filterOrderUpgrade = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterOrderUpgradeApi({
        begin: filter.begin !== 0 ? moment.unix(filter.begin).utc().unix() : 0,
        end: filter.end !== 0 ? moment.unix(filter.end).utc().unix() : 0,
        orderNo: !filter.orderNo ? undefined : filter.orderNo,
        userName: !filter.userName ? undefined : filter.userName,
        status: !filter.status ? undefined : filter.status,
        page,
        limit,
      });

      setOrders(data.results ?? []);
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const exportOrderCsv = async () => {
    try {
      setLoading(true);
      const response = await filterOrderUpgradeApi({
        begin: filter.begin !== 0 ? moment.unix(filter.begin).utc().unix() : 0,
        end: filter.end !== 0 ? moment.unix(filter.end).utc().unix() : 0,
        orderNo: !filter.orderNo ? undefined : filter.orderNo,
        userName: !filter.userName ? undefined : filter.userName,
        status: !filter.status ? undefined : filter.status,
        type: "csv",
      });

      let BOM = "\uFEFF";
      let csvData = (BOM + response.data) as any;
      let blob = new Blob([csvData], {
        type: "text/csv;charset=utf-8",
      });

      FileSaver.saveAs(blob, `order-upgrade.csv`);

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const confirmOrderUpgrade = async (id: string, status: 0 | 1 | 2 | 3) => {
    try {
      setLoading(true);
      await confirmOrderUpgradeApi({ id, status });
      const { data } = await filterOrderUpgradeApi({
        begin: filter.begin !== 0 ? moment.unix(filter.begin).utc().unix() : 0,
        end: filter.end !== 0 ? moment.unix(filter.end).utc().unix() : 0,
        orderNo: !filter.orderNo ? undefined : filter.orderNo,
        userName: !filter.userName ? undefined : filter.userName,
        status: !filter.status ? undefined : filter.status,
        page: meta.page,
        limit: meta.limit,
      });

      if (status === 3) {
        await getOrderUpgradeSummary();
      }
      setOrders(data.results ?? []);
      setMeta({
        page: data.meta.page,
        limit: data.meta.limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      openSnackbar("Success");
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    getOrderUpgradeSummary();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    filterOrderUpgrade(1, meta.limit);
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <Breadcrumb
        title={"สินค้าอัพเกรด"}
        children={[{ title: "หน้าแรก", link: "/" }, { title: "สินค้าอัพเกรด" }]}
      />

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills active" id="pills-tab" role="tablist">
            <li className="nav-item mt-2">
              <Link
                className={`nav-link ${
                  !pathname.endsWith("/orders") ? "active" : ""
                }`}
                to={`${process.env.PUBLIC_URL}/products-upgrade`}
              >
                สินค้าอัพเกรด
              </Link>
            </li>
            <li className="nav-item mt-2">
              <Link
                className={`nav-link ${
                  pathname.endsWith("/orders") ? "active" : ""
                }`}
                to={`${process.env.PUBLIC_URL}/products-upgrade/orders`}
              >
                คำสั่งซื้อ
                {badge.newOrderProductsUpgrade > 0 && (
                  <span className="badge badge-pill badge-danger ml-1">
                    {badge.newOrderProductsUpgrade}
                  </span>
                )}
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row  pb-2 mb-2">
                <div className="col mb-2">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-left justify-content-md-left mb-2 mb-md-0">
                        <div>
                          <p className="mb-1 tx-12 text-muted">ทั้งหมด</p>
                          <div className="d-flex align-items-center">
                            <h3 className="mb-0 mr-2">
                              {summary?.totalOrder.toLocaleString("en-US") ?? 0}
                            </h3>
                            <p className="tx-12 mb-0  mt-2 text-muted">
                              รายการ
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col mb-2">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-left justify-content-md-left mb-2 mb-md-0">
                        <div>
                          <p className="mb-1 tx-12 text-muted">ยอดขายสินค้า</p>
                          <div className="d-flex align-items-center">
                            <h3 className="mb-0 mr-2 text-success">
                              {summary?.totalAmount.toLocaleString("en-US")}
                            </h3>
                            <p className="tx-12 mb-0  mt-2 text-muted">บาท</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 ">
                  <span className="mr-2 ">
                    ทั้งหมด {meta.totalCount} รายการ
                  </span>
                </div>
                <div className="col-lg p-0">
                  <div className="form-row mb-2 ">
                    <div className="col-md mb-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="หมายเลขคำสั่งซื้อ"
                        value={filter.orderNo}
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            orderNo: e.target.value,
                          }));
                        }}
                      />
                    </div>

                    <div className="col-md mb-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="ชื่อสมาชิก"
                        value={filter.userName}
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            userName: e.target.value,
                          }));
                        }}
                      />
                    </div>

                    <div className="col-md mb-1">
                      <ReactDatePicker
                        selected={startDate}
                        onChange={(dates: [Date | null, Date | null]) => {
                          const [start, end] = dates;
                          setStartDate(start);
                          setEndDate(end);
                          if ((!!start && !!end) || (!start && !end)) {
                            setFilter((prev) => ({
                              ...prev,
                              begin: (start?.valueOf() ?? 0) / 1000,
                              end: (end?.valueOf() ?? 0) / 1000,
                            }));
                          }
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        dateFormat={"dd/MM/yyyy"}
                        placeholderText="วันที่ จาก - ถึง"
                        isClearable={true}
                        customInput={<input className="form-control" />}
                      />
                    </div>

                    <div className="col-lg-2 ml-auto">
                      <div className="form-group">
                        <select
                          className="form-control w-100"
                          value={filter.status}
                          onChange={(e) => {
                            setFilter((prev) => ({
                              ...prev,
                              status: e.target.value,
                            }));
                          }}
                        >
                          <option value="">ทั้งหมด</option>
                          <option value="2">รอยืนยัน</option>
                          <option value="3">ที่ต้องจัดส่ง</option>
                          <option value="1">จัดส่งแล้ว</option>
                          <option value="0">ยกเลิก</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-auto">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => {
                          exportOrderCsv();
                        }}
                      >
                        <span className="fa fa-download mr-2"></span>Export
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row"></div>

              <div className="row">
                <div className="table-responsive">
                  <table id="dataTable" className="table">
                    <thead>
                      <tr>
                        <th>คำสั่งซื้อ</th>
                        <th>สมาชิก</th>
                        <th>วันที่</th>
                        <th>สินค้า</th>
                        <th>ราคา</th>
                        <th style={{ width: 200 }}>ที่อยู่การจัดส่ง</th>
                        <th style={{ width: 200 }}>การจัดส่ง</th>
                        <th style={{ width: 150 }}>ไฟล์แนบ</th>
                        <th>การชำระเงิน</th>
                        <th>สถานะ</th>
                        <th className="text-right">ดำเนินการ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.length > 0 ? (
                        orders.map((order) => {
                          let status: ReactNode;
                          switch (order.status) {
                            case 0:
                              status = (
                                <span className="badge badge-small badge-danger">
                                  ยกเลิก
                                </span>
                              );
                              break;
                            case 1:
                              status = (
                                <span className="badge badge-small badge-success">
                                  สำเร็จ
                                </span>
                              );
                              break;
                            case 2:
                              status = (
                                <span className="badge badge-primary badge-warning">
                                  รอยืนยัน
                                </span>
                              );
                              break;
                            case 3:
                              status = (
                                <span className="badge badge-small badge-info">
                                  ที่ต้องจัดส่ง
                                </span>
                              );
                              break;
                            default:
                              status = (
                                <span className="badge badge-small badge-light">
                                  -
                                </span>
                              );
                          }

                          return (
                            <tr key={order.id}>
                              <td>
                                <div
                                  className="hover-underline cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `${process.env.PUBLIC_URL}/products-upgrade/orders/${order.id}`
                                    )
                                  }
                                >
                                  {order.orderNo}
                                </div>
                              </td>
                              <td>
                                <div
                                  className="hover-underline cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `${process.env.PUBLIC_URL}/users/edit/${order.userId}`
                                    )
                                  }
                                >
                                  {order.user?.firstName} {order.user?.lastName}
                                </div>
                              </td>
                              <td>
                                {moment(order.createdAt).format(
                                  "DD/MM/yyyy HH:mm:ss"
                                )}
                              </td>
                              <td>{order.productName}</td>
                              <td>
                                {order.productPrice.toLocaleString("en-US")}
                              </td>
                              <td>
                                <div>{order.shippingName}</div>
                                <div>{order.shippingPhone}</div>
                                <div>{order.shippingAddress}</div>
                              </td>
                              <td>
                                {!!order.shippingDate
                                  ? `${moment(order.shippingDate).format(
                                      "DD/MM/yyyy HH:mm:ss"
                                    )} ${order.shippingCarrier} : ${
                                      order.trackingNumber
                                    }`
                                  : ""}
                              </td>
                              <td>
                                {order.slip.length > 0 && (
                                  <div
                                    className="cursor-pointer img-thumbmail"
                                    onClick={() => {
                                      setShowImage({
                                        show: true,
                                        src: getSrcImage(order.slip),
                                      });
                                    }}
                                  >
                                    <img
                                      className="lazy img-responsive"
                                      alt=""
                                      src={getSrcImage(order.slip, "?tr=w-50")}
                                    />
                                  </div>
                                )}
                              </td>
                              <td>{order.paymentMethod}</td>
                              <td>{status}</td>
                              <td className="text-right">
                                <div className="dropdown">
                                  <button
                                    className="btn btn-outline-primary dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuIconButton2"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {" "}
                                    เพิ่มเติม
                                  </button>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuIconButton2"
                                  >
                                    {[2, 3].includes(order.status) && (
                                      <>
                                        {order.status === 2 && (
                                          <>
                                            <div
                                              className="dropdown-item cursor-pointer"
                                              onClick={() => {
                                                confirmOrderUpgrade(
                                                  order.id,
                                                  3
                                                );
                                              }}
                                            >
                                              <span className="fa fa-check mr-2 text-success"></span>
                                              อนุมัติ/ยืนยัน
                                            </div>
                                            <div
                                              className="dropdown-item cursor-pointer"
                                              onClick={() => {
                                                confirmOrderUpgrade(
                                                  order.id,
                                                  0
                                                );
                                              }}
                                            >
                                              <span className="fa fa-times mr-2 text-danger"></span>
                                              ยกเลิก
                                            </div>
                                          </>
                                        )}
                                        {order.status === 3 && (
                                          <div
                                            className="dropdown-item cursor-pointer"
                                            onClick={() => {
                                              confirmOrderUpgrade(order.id, 1);
                                            }}
                                          >
                                            <span className="fa fa-check mr-2 text-success">
                                              จัดส่งสำเร็จ
                                            </span>
                                          </div>
                                        )}
                                        <div className="dropdown-divider"></div>
                                      </>
                                    )}
                                    <div
                                      className="dropdown-item cursor-pointer"
                                      onClick={() => {
                                        navigate(
                                          `${process.env.PUBLIC_URL}/products-upgrade/orders/${order.id}`
                                        );
                                      }}
                                    >
                                      <span className="fa fa-edit mr-2"></span>
                                      รายละเอียด
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={11}>
                            <div className="text-center">ไม่พบรายการ</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Pagination
                    active={meta.page}
                    limit={meta.limit}
                    onPageChange={(page) => {
                      filterOrderUpgrade(page, meta.limit);
                    }}
                    pageRangeDisplayed={5}
                    total={meta.totalCount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage((prev) => ({
            ...prev,
            show: false,
          }));
        }}
      />
    </>
  );
};

export default ProductOrder;
