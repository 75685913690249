import { getSrcImage } from "../utils/utils";

const Page404 = () => {
  return (
    <div className="align-items-center d-flex flex-column h-100 justify-content-center">
      <img style={{ width: 500 }} src={getSrcImage("images/something-lost.png")} alt="something-lost" />
      <h1>Oops, looks like the page is lost.</h1>
      <p style={{ fontSize: 22 }} className="sub-header text-block-narrow">This is not a fault, just an accident that was not intentional.</p>
    </div>
  )
}

export default Page404; 