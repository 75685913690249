import { FC, useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useSnackbar } from 'react-simple-snackbar'
import { useAppDispatch } from "../../redux/hooks";
import { setAppLoading } from "../../redux/app.reducer";
import { createNewsApi, getNewsByIdApi, updateNewsByIdApi } from "../../api/news";
import { ICreateNews } from "../../interface/news";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { uploadImage } from "../../api/core";
import { getSrcImage } from "../../utils/utils";

const $ = require('jquery');
$.dropify = require("dropify");

interface INewsForm {
  mode: "new" | "edit"
}
const NewsForm: FC<INewsForm> = ({ mode }) => {
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [date, setDate] = useState(moment().format("yyyy-MM-DD"))
  const [time, setTime] = useState("00:00:00")
  const [news, setNews] = useState<ICreateNews & { submit: boolean }>({
    cover: "",
    title: "",
    description: "",
    note: "",
    effectiveDate: 0,
    status: 0,
    forRoles: [],
    submit: false,
  });
  const [loading, setLoading] = useState(false);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
    setNews(prev => ({
      ...prev,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    }))
  };

  const initDropify = (defaultFile?: string) => {
    const drEvent = $('.dropify').dropify({
      defaultFile: !!defaultFile ? getSrcImage(defaultFile) : "",
    });
    drEvent.on('dropify.afterClear', function (event, element) {
      setNews(prev => ({
        ...prev,
        cover: "",
      }))
    });
  }

  const getNewsById = async () => {
    try {
      setLoading(true);

      const { data } = await getNewsByIdApi(id!);
      setNews((prev) => ({
        ...prev,
        ...data
      }))

      initDropify(data.cover)

      const blocks = htmlToDraft(data.description);
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(blocks.contentBlocks)
        )
      )

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/news`)
    }
  }

  const createNews = async () => {
    try {
      setLoading(true);

      await createNewsApi(news);

      setLoading(false);
      openSnackbar("Successfuly");
      setTimeout(() => {
        navigate(`${process.env.PUBLIC_URL}/news`)
      }, 500)
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const updateNews = async () => {
    try {
      setLoading(true);

      await updateNewsByIdApi(id!, news);

      setLoading(false);
      openSnackbar("Successfuly");
      setTimeout(() => {
        navigate(`${process.env.PUBLIC_URL}/news`)
      }, 500)
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const uploadCallback = (file, callback) => {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      reader.onloadend = async () => {
        const payload = new FormData();
        payload.append("fileType", "news");
        payload.append("file", file);
        const { data } = await uploadImage(payload);
        resolve({ data: { link: `${process.env.REACT_APP_IMAGE_HOST}/${data}` } });
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    if (mode === "new") {
      initDropify()
    }
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (!news.submit) return

    if (mode === "new") {
      createNews();
    } else {
      updateNews();
    }
    // eslint-disable-next-line
  }, [news.submit])

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (mode === "edit") {
      getNewsById();
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Breadcrumb
        title={"เพิ่มประกาศข่าว"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "ประกาศข่าว", link: "/news" }
        ]}
      />


      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body" >
              <form className="forms-sample" onSubmit={(e) => {
                e.preventDefault();
                const effectiveDate = moment(`${date} ${time}`).unix()
                setNews(prev => ({
                  ...prev,
                  effectiveDate: effectiveDate,
                  forRoles: ["user"],
                  submit: true,
                }))
              }}>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">หัวข้อ</label>
                  <div className="col-sm-5">
                    <input type="text" required className="form-control" value={news.title} onChange={(e) => {
                      setNews(prev => ({
                        ...prev,
                        title: e.target.value,
                      }))
                    }} />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">อัพโหลดรูปภาพ</label>
                  <div className="col-sm-5">
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        onChange={(e) => {
                          let reader = new FileReader();
                          reader.readAsDataURL(e.target.files![0]);
                          reader.onload = function () {
                            setNews(prev => ({
                              ...prev,
                              cover: reader.result as string,
                            }))
                          };
                        }}
                        className="dropify"
                        data-max-file-size="3M"
                        data-allowed-file-extensions="jpg png jpeg"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">รายละเอียด</label>
                  <div className="col-sm-8">
                    <Editor
                      editorState={editorState}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={onEditorStateChange}
                      toolbar={{
                        options: ['inline', 'fontSize', 'list', 'textAlign', 'image', 'history'],
                        inline: {
                          options: ['bold', 'italic', 'underline'],
                        },
                        fontSize: {
                          options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                        },
                        image: {
                          urlEnabled: true,
                          uploadEnabled: true,
                          alignmentEnabled: true,
                          uploadCallback: uploadCallback,
                          previewImage: true,
                          inputAccept: 'image/jpeg,image/jpg,image/png',
                          alt: { present: false, mandatory: false },
                          defaultSize: {
                            height: 'auto',
                            width: 'auto',
                          },
                        },
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">วันที่ส่ง</label>
                  <div className="col-sm-3">
                    <input
                      type="date"
                      required
                      className="form-control"
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value)
                      }}
                      disabled={mode === "edit"}
                    />
                  </div>
                  <div className="col-sm-2">
                    <input
                      type="time"
                      required
                      className="form-control"
                      value={time}
                      onChange={(e) => {
                        setTime(e.target.value)
                      }}
                      disabled={mode === "edit"}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">หมายเหตุ</label>
                  <div className="col-sm-5">
                    <input type="text" className="form-control" onChange={(e) => {
                      setNews(prev => ({
                        ...prev,
                        note: e.target.value,
                      }))
                    }} />
                  </div>
                </div>
                <div className="row">
                  <button className="btn btn-outline-primary mr-2 ml-auto" onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/news`)
                  }}>ยกเลิก</button>
                  <button className="btn btn-primary mr-2" type="submit">บันทึก</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default NewsForm;
