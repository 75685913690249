import { AxiosResponse } from "axios";
import { IAffiliate } from "../interface/affiliate";
import { IBadge } from "../interface/badge";
import IBank from "../interface/bank";
import { IBanner } from "../interface/banner";
import { ICommission } from "../interface/commission";
import { IInformation } from "../interface/information";
import { IPricing } from "../interface/pricing";
import { IRule } from "../interface/rule";
import { http } from "../utils/http";

export const uploadImage = async (
  payload: FormData
): Promise<AxiosResponse<string>> => {
  return await http.post("/upload/image", payload);
};

export const getPricingApi = async (): Promise<AxiosResponse<IPricing[]>> => {
  return await http.get("/v1/pricing");
};

export const createPricingApi = async (
  payload: IPricing
): Promise<AxiosResponse<IPricing[]>> => {
  return await http.post("/v1/admin/pricing", payload);
};

export const updatePricingApi = async (
  payload: IPricing
): Promise<AxiosResponse<IPricing[]>> => {
  return await http.put(`/v1/admin/pricing/${payload.id}`, payload);
};

export const deletePricingApi = async (
  id: number
): Promise<AxiosResponse<IPricing[]>> => {
  return await http.del(`/v1/admin/pricing/${id}`);
};

export const updateInformationApi = async (
  payload: IInformation
): Promise<AxiosResponse<IInformation>> => {
  return await http.put("/v1/admin/information", payload);
};

export const getInformationApi = async (): Promise<
  AxiosResponse<IInformation>
> => {
  return await http.get("/v1/admin/information");
};

export const updateCommissionConfigApi = async (
  payload: ICommission
): Promise<AxiosResponse<ICommission>> => {
  return await http.put("/v1/admin/commission-config", payload);
};

export const getCommissionConfigApi = async (): Promise<
  AxiosResponse<ICommission>
> => {
  return await http.get("/v1/admin/commission-config");
};

export const getRuleByUserIdApi = async (
  id: string
): Promise<AxiosResponse<IRule>> => {
  return await http.get(`/v1/admin/rule/${id}`);
};

export const getBadgeOrderApi = async (): Promise<AxiosResponse<IBadge>> => {
  return await http.get(`/v1/admin/badge/order`);
};

export const getBadgeOrderUpgradeApi = async (): Promise<
  AxiosResponse<IBadge>
> => {
  return await http.get(`/v1/admin/badge/order-upgrade`);
};

export const getBadgeNewWithdrawApi = async (): Promise<
  AxiosResponse<IBadge>
> => {
  return await http.get(`/v1/admin/badge/new-withdraw`);
};

export const subsctibeToTopicApi = async (
  fcmToken: string
): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/auth/subscribe/${fcmToken}`, null);
};

export const unsubsctibeFromTopicApi = async (
  fcmToken: string
): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/auth/unsubscribe/${fcmToken}`, null, false);
};

export const getBanksApi = async (): Promise<AxiosResponse<IBank[]>> => {
  return await http.get("/v1/banks");
};

export const updateBannerApi = async (
  payload: IBanner
): Promise<AxiosResponse<IBanner>> => {
  return await http.put("/v1/admin/banners", payload);
};

export const getBannerApi = async (): Promise<AxiosResponse<IBanner>> => {
  return await http.get("/v1/admin/banners");
};

export const updateAffiliateApi = async (
  payload: IAffiliate
): Promise<AxiosResponse<IAffiliate>> => {
  return await http.put("/v1/admin/affiliate", payload);
};

export const getAffiliateApi = async (): Promise<AxiosResponse<IAffiliate>> => {
  return await http.get("/v1/admin/affiliate");
};
