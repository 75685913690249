import React, { useEffect, useImperativeHandle } from "react";
import { createRoot } from "react-dom/client";
import 'datatables.net-select-bs4'
import { Spinner } from "reactstrap";
import _ from 'lodash';
import { useAppConfirm } from "../redux/hooks";

const $ = require('jquery')
$.DataTable = require("datatables.net-bs4");

interface IDatatable {
  id?: string;
  loading?: boolean;
  columns: {
    isCheckbox?: boolean;
    data?: string | null | ((data: any, type: string, set: any, meta: object) => string);
    width?: string | number;
    className?: string;
    title: string;
    createdCell?: (cell: any, cellData: any, rowData: any, rowIndex: number, colIndex: number) => void,
    render?: (data: any, type: string, row: any, meta: object) => string,
  }[],
  data?: any[] | null,
  actions?: {
    button: {
      callback?: (data) => void;
      condition?: (data) => boolean;
      code: React.ReactNode;
    },
    confirm?: {
      title: string;
      description?: string;
      textCancel?: string;
      classBtnCancel?: string;
      textConfirm?: string;
      classBtnConfirm?: string;
    },
  }[],
}

export interface DatatableElement {
  selected(): any
}

const Datatable = React.forwardRef<DatatableElement, IDatatable>(({ id = "dataTable", loading, columns, data, actions }, ref) => {
  let [dataTableRef, setDataTableRef] = React.useState<any>(undefined)
  const { confirm } = useAppConfirm();
  const renderAction = (actions, data, rowIndex) => {
    return actions.map((action, index) => {
      let render = false
      if (typeof action.button.condition === "function") {
        if (action.button.condition(data)) {
          render = true
        }
      } else {
        render = true
      }

      return render ? React.createElement(
        action.button.code.type,
        {
          ...action.button.code.props,
          onClick: async (event) => {
            if (!!action.confirm) {
              let title = action.confirm.title;
              const titleVals = [...new Set<string>(action.confirm.title?.match(/#{(.*?)}/g) ?? [])];
              let description = action.confirm.description;
              const descriptionVals = [...new Set<string>(action.confirm.description?.match(/#{(.*?)}/g) ?? [])];

              titleVals.forEach(val => {
                title = title.split(val).join(_.get(data, val.substring(2, val.length - 1)))
              })

              descriptionVals.forEach(e => {
                description = description.split(e).join(_.get(data, e.substring(2, e.length - 1)))
              })

              const isConfirmed = await confirm({
                title: title,
                description: description,
                textCancel: action.confirm.textCancel,
                textConfirm: action.confirm.textConfirm,
                classBtnCancel: action.confirm.classBtnCancel,
                classBtnConfirm: action.confirm.classBtnConfirm,
              });

              if (isConfirmed)
                action.button.callback(data, event);
            } else {
              action.button.callback(data, event);
            }
          },
          key: `action_btn_${rowIndex}_${index}`
        },
      ) : null;
    }).filter(e => e != null)
  }

  const renderColumns = () => {
    const cols = columns.map((col) => {
      if (col.isCheckbox) {
        return ({
          ...col,
          render: () => {
            return ""
          },
          title: `<input type="checkbox" class="selectAll " name="selectAll" value="all"/>`
        })
      }
      return col;
    })

    if (!!actions && actions.length > 0) {
      cols.push({
        title: "ดำเนินการ",
        data: null,
        width: 120,
        createdCell(cell, cellData, rowData, rowIndex, colIndex) {
          createRoot(cell).render(
            <div
              className="d-flex flex-column flex-md-row justify-content-center"
              style={{
                gap: "0.25rem",
                width: "inherit"
              }} >
              {renderAction(actions, rowData, rowIndex)}
            </div >
          )
        },
      })
    }

    return cols
  }

  useImperativeHandle(ref, () => ({
    selected() {
      const data: {}[] = [];
      dataTableRef.rows({ selected: true }).data().each(e => data.push(e))
      return data
    }
  }))

  useEffect(() => {
    if (!!dataTableRef) {
      dataTableRef.clear().rows.add(data).draw();
      // eslint-disable-next-line
      dataTableRef.rows().every(function (this: any, rowIdx, tableLoop, rowLoop) {
        if (this.data().selected === true) {
          this.select();
        }
      });
      return;
    }

    const hasCheckbox = columns.filter(e => e.isCheckbox).length > 0
    const tb = $(`#${id}`);
    const dataTable = tb.DataTable({
      "bLengthChange": false,
      searching: false,
      paging: false,
      ordering: false,
      info: false,
      scrollX: true,
      columnDefs: [
        hasCheckbox ? ({
          orderable: false,
          className: 'select-checkbox',
          targets: 0,
        }) : {}
      ],
      select: hasCheckbox ? ({
        style: 'multi',
        selector: 'td:first-child'
      }) : {},
      columns: renderColumns(),
      data: data,
    });
    setDataTableRef(dataTable);

    if (hasCheckbox) {
      $(`#${id}_wrapper .selectAll`).on('click', function () {
        if ($(`#${id}_wrapper .selectAll`).is(':checked')) {
          dataTable.rows().select();
        }
        else {
          dataTable.rows().deselect();
        }
      });
    }

    // eslint-disable-next-line
  }, [data])

  return (
    <div style={{ position: "relative" }}>
      <table id={id} className="table" style={{ minWidth: "100%" }} />
      {
        loading && (
          <div
            style={{
              background: "#0000001a",
              height: "Calc(100% - 10px)",
              position: "absolute",
              zIndex: 1,
              width: "100%",
              top: 6,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner style={{ color: "#1EB863" }}>{" "}</Spinner>
          </div>
        )
      }
    </div>
  )
})

export default Datatable;