import { AxiosResponse } from "axios";
import { ICreateFooter, IFooter, IFooterWithPagignation } from "../interface/footer";
import { http } from "../utils/http";

export const filterFooterApi = async (payload: {
  status?: string;
  begin?: number;
  end?: number
  page?: number;
  limit?: number;
}): Promise<AxiosResponse<IFooterWithPagignation>> => {
  return await http.get("/v1/admin/footer/filter", payload)
};

export const createFooterApi = async (payload: ICreateFooter) => {
  return await http.post("/v1/admin/footer", payload)
};

export const deleteFooterByIdApi = async (id: string) => {
  return await http.del(`/v1/admin/footer/${id}`)
};

export const getFooterByIdApi = async (id: string): Promise<AxiosResponse<IFooter>> => {
  return await http.get(`/v1/admin/footer/${id}`)
};

export const updateFooterByIdApi = async (id: string, payload: ICreateFooter) => {
  return await http.put(`/v1/admin/footer/${id}`, payload)
};