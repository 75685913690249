import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getLatestLottery,
  getLotteryResultByIdApi,
  getPeriodLotteryByIdApi,
  updateLotteryResultTierByIdApi,
} from "../../api/lottery";
import Breadcrumb from "../../component/breadcrumb";
import {
  ILotteryResult,
  ILotteryResultNumber,
  IPeriodLottery,
} from "../../interface/lottery";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { useMutation } from "react-query";

interface CurrentLotteryResult {
  statusMessage: string;
  statusCode: number;
  message?: any;
  status: boolean;
  response: Response;
}

interface Response {
  youtube_url: string;
  pdf_url: string;
  date: string;
  period: number[];
  remark?: any;
  status: number;
  sheetId: string;
  data: Data;
  displayDate: DisplayDate;
}

interface DisplayDate {
  date: string;
  month: string;
  year: string;
}

interface Data {
  first: Result;
  second: Result;
  third: Result;
  fourth: Result;
  fifth: Result;
  last2: Result;
  last3f: Result;
  last3b: Result;
  near1: Result;
}

interface Result {
  price: string;
  number: Number[];
}

interface Number {
  round: number;
  value: string;
}

const LotteryAnnouncement = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(false);
  const [periodLottery, setPeriodLottery] = useState<IPeriodLottery>();
  const { data, isLoading, mutate } = useMutation({
    mutationKey: ["getLatestLottery"],
    mutationFn: async (): Promise<CurrentLotteryResult> => {
      return (await getLatestLottery()).data;
    },
  });
  const [result, setResult] = useState<ILotteryResult>({
    first: [],
    first3: [],
    last3: [],
    last2: [],
    near1: [],
    second: [],
    third: [],
    fourth: [],
    fifth: [],
  });

  const initData = async () => {
    try {
      setLoading(true);

      const period = await getPeriodLotteryByIdApi(id!);
      const result = await getLotteryResultByIdApi(id!);
      setPeriodLottery(period.data);
      setResult(result.data);

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/lottery`);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const splitName = e.target.name.split(".");
    if (splitName.length !== 2) {
      openSnackbar("Error: Something wrong.");
      return;
    }
    const tier = splitName[0];
    const round = +splitName[1];
    const newValue = result[tier].map((val: ILotteryResultNumber) => {
      if (val.round === round) {
        return { ...val, value: e.target.value };
      }
      return val;
    });

    setResult((prev) => ({
      ...prev,
      [tier]: newValue,
    }));
  };

  const onSave = async (tier: string, payload: ILotteryResultNumber[]) => {
    try {
      setLoading(true);
      await updateLotteryResultTierByIdApi(id!, {
        tier,
        payload,
      });
      setLoading(false);
      openSnackbar("อัพเดทข้อมูลสำเร็จ");
    } catch (error: any) {
      setLoading(false);
      let msg;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const onSync = () => {
    mutate();
  };

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && periodLottery) {
      const date = moment([
        periodLottery.period.year,
        periodLottery.period.month - 1,
        periodLottery.period.day,
      ]).format("YYYY-MM-DD");
      if (data.response.date === date) {
        const {
          first,
          second,
          third,
          fourth,
          fifth,
          near1,
          last2,
          last3b,
          last3f,
        } = data.response.data;
        setResult({
          first: first.number
            .sort((a, b) => {
              return a.round - b.round;
            })
            .map((num) => ({
              round: num.round,
              value: num.value,
            })),
          first3: last3f.number
            .sort((a, b) => {
              return a.round - b.round;
            })
            .map((num) => ({
              round: num.round,
              value: num.value,
            })),
          last2: last2.number
            .sort((a, b) => {
              return a.round - b.round;
            })
            .map((num) => ({
              round: num.round,
              value: num.value,
            })),
          last3: last3b.number
            .sort((a, b) => {
              return a.round - b.round;
            })
            .map((num) => ({
              round: num.round,
              value: num.value,
            })),
          second: second.number
            .sort((a, b) => {
              return a.round - b.round;
            })
            .map((num) => ({
              round: num.round,
              value: num.value,
            })),
          third: third.number
            .sort((a, b) => {
              return a.round - b.round;
            })
            .map((num) => ({
              round: num.round,
              value: num.value,
            })),
          fourth: fourth.number
            .sort((a, b) => {
              return a.round - b.round;
            })
            .map((num) => ({
              round: num.round,
              value: num.value,
            })),
          fifth: fifth.number
            .sort((a, b) => {
              return a.round - b.round;
            })
            .map((num) => ({
              round: num.round,
              value: num.value,
            })),
          near1: near1.number
            .sort((a, b) => {
              return a.round - b.round;
            })
            .map((num) => ({
              round: num.round,
              value: num.value,
            })),
        });
      } else {
        alert(`ไม่พบงวดที่ท่านต้องการ งวดล่าสุดคือ ${data.response.date}`);
      }
    }
  }, [data, periodLottery]);

  return (
    <>
      <Breadcrumb
        title={`ประกาศรางวัล ${periodLottery?.period.day ?? ""} ${
          !!periodLottery ? moment.months(periodLottery!.period.month - 1) : ""
        } ${!!periodLottery ? periodLottery!.period.year + 543 : ""}`}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "งวดลอตเตอรี่", link: "/lottery" },
          {
            title: `ประกาศรางวัล ${periodLottery?.period.day ?? ""} ${
              !!periodLottery
                ? moment.months(periodLottery!.period.month - 1)
                : ""
            } ${!!periodLottery ? periodLottery!.period.year + 543 : ""}`,
          },
        ]}
      />

      <div className="row mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <button
                className="btn btn-md btn-outline-primary float-right"
                onClick={onSync}
                disabled={isLoading}
              >
                ดึงผลสลาก
              </button>
              <br />
              <br />
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr
                      className="text-center"
                      style={{ background: "#71717121" }}
                    >
                      <td>
                        <strong className="h5" style={{ color: "#0C3A2D" }}>
                          รางวัลที่ 1
                        </strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td>
                        <input
                          type="text"
                          className="h1 text-center p-1 font-weight-bold"
                          maxLength={6}
                          placeholder="รางวัลที่ 1"
                          value={result.first[0]?.value ?? ""}
                          name={"first.1"}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="text-center p-1">
                        <button
                          className="btn btn-sm btn-outline-primary m-auto"
                          onClick={() => onSave("first", result.first)}
                        >
                          <span className="fa fa-save mr-2"></span>บันทึก
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr
                      className="text-center"
                      style={{ background: "#71717121" }}
                    >
                      <td colSpan={2}>
                        <strong className="h5" style={{ color: "#0C3A2D" }}>
                          เลขหน้า 3 ตัว
                        </strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      {Array.from({ length: 2 }).map((_, index) => (
                        <td key={`first3_${index}`}>
                          <input
                            type="text"
                            className="h2 text-center font-weight-bold"
                            maxLength={3}
                            placeholder="เลขหน้า 3 ตัว"
                            value={result.first3[index]?.value ?? ""}
                            name={`first3.${index + 1}`}
                            onChange={handleChange}
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2} className="text-center p-1">
                        <button
                          className="btn btn-sm btn-outline-primary m-auto"
                          onClick={() => onSave("first3", result.first3)}
                        >
                          <span className="fa fa-save mr-2"></span>บันทึก
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr
                      className="text-center"
                      style={{ background: "#71717121" }}
                    >
                      <td colSpan={2}>
                        <strong className="h5" style={{ color: "#0C3A2D" }}>
                          เลขท้าย 3 ตัว
                        </strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      {Array.from({ length: 2 }).map((_, index) => (
                        <td key={`last3${index}`}>
                          <input
                            type="text"
                            className="h2 text-center font-weight-bold"
                            maxLength={3}
                            placeholder="เลขท้าย 3 ตัว"
                            value={result.last3[index]?.value ?? ""}
                            name={`last3.${index + 1}`}
                            onChange={handleChange}
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2} className="text-center p-1">
                        <button
                          className="btn btn-sm btn-outline-primary m-auto"
                          onClick={() => onSave("last3", result.last3)}
                        >
                          <span className="fa fa-save mr-2"></span>บันทึก
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr
                      className="text-center"
                      style={{ background: "#71717121" }}
                    >
                      <td colSpan={5}>
                        <strong className="h5" style={{ color: "#0C3A2D" }}>
                          เลขท้าย 2 ตัว
                        </strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      {Array.from({ length: 1 }).map((_, index) => (
                        <td key={`last2${index}`}>
                          <input
                            type="text"
                            className="h2 text-center font-weight-bold"
                            maxLength={2}
                            placeholder="เลขท้าย 2 ตัว"
                            value={result.last2[index]?.value ?? ""}
                            name={`last2.${index + 1}`}
                            onChange={handleChange}
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="text-center p-1">
                        <button
                          className="btn btn-sm btn-outline-primary m-auto"
                          onClick={() => onSave("last2", result.last2)}
                        >
                          <span className="fa fa-save mr-2"></span>บันทึก
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr
                      className="text-center"
                      style={{ background: "#71717121" }}
                    >
                      <td colSpan={2}>
                        <strong className="h5" style={{ color: "#0C3A2D" }}>
                          รางวัลข้างเคียงรางวัลที่ 1
                        </strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      {Array.from({ length: 2 }).map((_, index) => (
                        <td key={`near1${index}`}>
                          <input
                            type="text"
                            className="h5 text-center font-weight-bold"
                            maxLength={6}
                            placeholder="รางวัลข้างเคียงรางวัลที่ 1"
                            value={result.near1[index]?.value ?? ""}
                            name={`near1.${index + 1}`}
                            onChange={handleChange}
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2} className="text-center p-1">
                        <button
                          className="btn btn-sm btn-outline-primary m-auto"
                          onClick={() => onSave("near1", result.near1)}
                        >
                          <span className="fa fa-save mr-2"></span>บันทึก
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr
                      className="text-center"
                      style={{ background: "#71717121" }}
                    >
                      <td colSpan={5}>
                        <strong className="h5" style={{ color: "#0C3A2D" }}>
                          ผลสลากกินแบ่งรัฐบาล รางวัลที่ 2
                        </strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      {Array.from({ length: 5 }).map((_, index) => (
                        <td key={`second${index}`}>
                          <input
                            type="text"
                            className="text-center font-weight-bold"
                            maxLength={6}
                            placeholder="รางวัลที่ 2"
                            value={result.second[index]?.value ?? ""}
                            name={`second.${index + 1}`}
                            onChange={handleChange}
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={5} className="text-center p-1">
                        <button
                          className="btn btn-sm btn-outline-primary m-auto"
                          onClick={() => onSave("second", result.second)}
                        >
                          <span className="fa fa-save mr-2"></span>บันทึก
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr
                      className="text-center"
                      style={{ background: "#71717121" }}
                    >
                      <td colSpan={5}>
                        <strong className="h5" style={{ color: "#0C3A2D" }}>
                          ผลสลากกินแบ่งรัฐบาล รางวัลที่ 3
                        </strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: 2 }).map((_, row) => (
                      <tr className="text-center" key={`third.row.${row}`}>
                        {Array.from({ length: 5 }).map((_, index) => {
                          return (
                            <td key={`third${index + 5 * row}`}>
                              <input
                                type="text"
                                className="text-center font-weight-bold"
                                maxLength={6}
                                placeholder="รางวัลที่ 3"
                                value={
                                  result.third[index + 5 * row]?.value ?? ""
                                }
                                name={`third.${index + 5 * row + 1}`}
                                onChange={handleChange}
                              />
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={5} className="text-center p-1">
                        <button
                          className="btn btn-sm btn-outline-primary m-auto"
                          onClick={() => onSave("third", result.third)}
                        >
                          <span className="fa fa-save mr-2"></span>บันทึก
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr
                      className="text-center"
                      style={{ background: "#71717121" }}
                    >
                      <td colSpan={5}>
                        <strong className="h5" style={{ color: "#0C3A2D" }}>
                          ผลสลากกินแบ่งรัฐบาล รางวัลที่ 4
                        </strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: 10 }).map((_, row) => (
                      <tr className="text-center" key={`fourth.row.${row}`}>
                        {Array.from({ length: 5 }).map((_, index) => {
                          return (
                            <td key={`fourth${index + 5 * row}`}>
                              <input
                                type="text"
                                className="text-center font-weight-bold"
                                maxLength={6}
                                placeholder="รางวัลที่ 4"
                                value={
                                  result.fourth[index + 5 * row]?.value ?? ""
                                }
                                name={`fourth.${index + 5 * row + 1}`}
                                onChange={handleChange}
                              />
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={5} className="text-center p-1">
                        <button
                          className="btn btn-sm btn-outline-primary m-auto"
                          onClick={() => onSave("fourth", result.fourth)}
                        >
                          <span className="fa fa-save mr-2"></span>บันทึก
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr
                      className="text-center"
                      style={{ background: "#71717121" }}
                    >
                      <td colSpan={5}>
                        <strong className="h5" style={{ color: "#0C3A2D" }}>
                          ผลสลากกินแบ่งรัฐบาล รางวัลที่ 5
                        </strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: 20 }).map((_, row) => (
                      <tr className="text-center" key={`fifth.row.${row}}`}>
                        {Array.from({ length: 5 }).map((_, index) => {
                          return (
                            <td key={`fifth${index + 5 * row}`}>
                              <input
                                type="text"
                                className="text-center font-weight-bold"
                                maxLength={6}
                                placeholder="รางวัลที่ 5"
                                value={
                                  result.fifth[index + 5 * row]?.value ?? ""
                                }
                                name={`fifth.${index + 5 * row + 1}`}
                                onChange={handleChange}
                              />
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={5} className="text-center p-1">
                        <button
                          className="btn btn-sm btn-outline-primary m-auto"
                          onClick={() => onSave("fifth", result.fifth)}
                        >
                          <span className="fa fa-save mr-2"></span>บันทึก
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LotteryAnnouncement;
