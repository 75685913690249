import { Fragment } from "react";
import { Link } from "react-router-dom";

interface IBreadcrumb {
  title: string;
  children: {
    title: string;
    link?: string;
  }[]
}
const Breadcrumb = (props: IBreadcrumb) => {
  return (
    <div className="d-flex align-items-center justify-content-between flex-wrap pb-2 mb-2">
      <div className="d-flex align-items-center">
        <h4 className="mb-0 font-weight-bold">{props.title}</h4>
        <div className="d-none d-md-flex ml-2 mt-2">
          {
            props.children.map((child, index) => {
              return (
                <Fragment key={"b_" + index}>
                  {
                    (!child.link) ? (
                      <p className="text-muted mb-0 tx-13">{child.title}</p>
                    ) : (
                      <Link to={child.link} className="text-muted mb-0 tx-13 cursor-pointer">{child.title}</Link>
                    )
                  }
                  {
                    (index !== props.children.length - 1) && (
                      <i className="mdi mdi-chevron-right text-muted"></i>
                    )
                  }
                </Fragment>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Breadcrumb;