import { AxiosResponse } from "axios";
import { IOrderUpgrade, IOrderUpgradeSummary, IOrderUpgradeWithPagignation } from "../interface/order_upgrade";
import { http } from "../utils/http";

export const filterOrderUpgradeApi = async (payload: {
  paymentMethod?: string;
  userId?: string;
  userName?: string;
  orderNo?: string;
  status?: string;
  begin?: number;
  end?: number;
  page?: number;
  limit?: number;
  type?: "csv";
}): Promise<AxiosResponse<IOrderUpgradeWithPagignation>> => {
  return await http.get("/v1/admin/order-upgrade/filter", payload)
};

export const getOrderUpgradeByIdApi = async (id: string): Promise<AxiosResponse<IOrderUpgrade>> => {
  return await http.get(`/v1/admin/order-upgrade/${id}`)
};

export const getOrderUpgradeSummaryApi = async (): Promise<AxiosResponse<IOrderUpgradeSummary>> => {
  return await http.get(`/v1/admin/order-upgrade/summary`)
};


export const confirmOrderUpgradeApi = async (payload: { id: string, status: 0 | 1 | 2 | 3 }): Promise<AxiosResponse<any>> => {
  return await http.post(`/v1/admin/order-upgrade/confirm`, payload)
};


export const updateShippingOrderUpgradeApi = async (payload: {
  id: string,
  shippingDate: number,
  shippingCarrier: string,
  trackingNumber: string,
}): Promise<AxiosResponse<any>> => {
  return await http.put(`/v1/admin/order-upgrade/shipping`, payload)
};