import Breadcrumb from "../../component/breadcrumb";
import { FC, ReactNode, useEffect, useState } from "react";
import { createPeriodLotteryApi, filterPeriodLotteryApi, updatePeriodLotteryByIdApi } from "../../api/lottery";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { IPeriodLottery, IPeriodLotteryRequest } from "../../interface/lottery";
import { setAppLoading } from "../../redux/app.reducer";
import FormPeriodLotteryModal from "./form-period-lottery-modal";
import moment from "moment";
import Pagination from "../../component/pagination";
import Meta from "../../interface/meta";
import { Link } from "react-router-dom";
import CheckLottery from "../../component/check-lottery";
import { Card, CardBody } from "reactstrap";

interface IRenderPeriod {
  period: IPeriodLottery;
  onClickEdit: (period: IPeriodLottery) => void;
}
const RenderPeriod: FC<IRenderPeriod> = ({ period, onClickEdit }) => {

  let status: ReactNode;
  switch (period.status) {
    case 0:
      status = (
        <span className="badge badge-small badge-danger">ปิด</span>
      );
      break;
    case 1:
      status = (
        <span className="badge badge-small badge-success">เปิด</span>
      );
      break;
    default:
      status = (
        <span className="badge badge-small badge-light">-</span>
      );
  }

  return (
    <div key={period.id} className="card mb-2">
      <div className="card-body">
        <div className="row">
          <div className="col-auto  mb-1">
            <div className="btn btn-primary">
              <h2 className="m-0">{period.period.day}</h2>
              <h4 className="m-0">{moment.monthsShort(period.period.month - 1)}</h4>
            </div>
          </div>

          <div className="col ">
            <div>{period.period.day} {moment.months(period.period.month - 1)} {period.period.year + 543}</div>
            {status}
            <br />
          </div>
          <div className="col d-none d-md-flex">
            <span className="text-muted tx-12 m-0 p-0">
              {moment(period.openDatetime).format("DD/MM/yyyy HH:mm:ss")}
              <div className="text-center">-</div>
              {moment(period.closeDatetime).format("DD/MM/yyyy HH:mm:ss")}
            </span>
          </div>

          <div className="col-lg ">
            <div className="d-lg-none text-muted tx-12">ลอตเตอรี่</div>
            <Link to={`/lottery/detail/${period.id}`} className="btn btn-outline-primary  mb-1 pt-2 btn-block">{period.totalLottery.toLocaleString('en-US')}</Link>
          </div>

          <div className="col-lg ">
            <div className="d-lg-none text-muted tx-12">ประกาศรางวัล</div>
            <Link to={`/lottery/announcement/${period.id}`} className="btn btn-outline-primary  mb-1 pt-2  btn-block">{period.totalLotteryResult.toLocaleString('en-US')}</Link>
          </div>
          <div className="col-lg ">
            <div className="d-lg-none text-muted tx-12">ถูกรางวัล</div>
            <Link to={`/lottery/winner/${period.id}`} className="btn btn-outline-primary  mb-1 pt-2  btn-block">{period.winner.toLocaleString('en-US')}</Link>
          </div>
          <div className="col text-right">
            <button className="btn btn-outline-primary mb-1 pt-2" onClick={() => { onClickEdit(period) }}>
              <span className="fa fa-edit "></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
const Lottery = () => {
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [modalFormPeriod, setModalFromPeriod] = useState({ mode: "", open: false, id: "" });
  const [loading, setLoading] = useState(true);
  const [periodLottery, setPeriodLottery] = useState<IPeriodLottery[]>([]);
  const [meta, setMeta] = useState<Meta>({
    page: 1,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });

  const createPeriodLottery = async (payload: IPeriodLotteryRequest) => {
    try {
      setLoading(true);
      await createPeriodLotteryApi(payload);
      setModalFromPeriod({ mode: "", open: false, id: "" });
      await filterPeriodLottery(meta.page, meta.limit);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const updatePeriodLotteryById = async (id: string, payload: IPeriodLotteryRequest) => {
    try {
      setLoading(true);
      await updatePeriodLotteryByIdApi(id, payload);
      setModalFromPeriod({ mode: "", open: false, id: "" });
      await filterPeriodLottery(meta.page, meta.limit);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const filterPeriodLottery = async (page: number, limit: number) => {
    try {
      const { data } = await filterPeriodLotteryApi({ page, limit });

      setPeriodLottery(data.results ?? [])
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    filterPeriodLottery(1, meta.limit);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <div className="dashboard-header d-flex flex-column grid-margin ">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <Breadcrumb
            title={"งวดลอตเตอรี่"}
            children={[
              { title: "หน้าแรก", link: "/" },
              { title: "งวดลอตเตอรี่" },
            ]}
          />

          <button className="btn btn-primary d-none d-md-block ml-auto" onClick={() => { setModalFromPeriod({ mode: "new", open: true, id: "" }) }}>
            <span className="fa fa-plus mr-2"></span>เพิ่มงวด
          </button>
        </div>
      </div>
      <CheckLottery />

      <div className="row ">
        <div className="col-12">
          <div className="row pb-3 pr-4 pl-4 pt-3 d-none d-md-flex">
            <div className="col-3 font-weight-bold tx-13 text-muted">งวดที่</div>
            <div className="col font-weight-bold tx-13 text-muted">วันที่</div>
            <div className="col font-weight-bold tx-13 text-muted">ลอตเตอรี่</div>
            <div className="col font-weight-bold tx-13 text-muted">ประกาศรางวัล</div>
            <div className="col font-weight-bold tx-13 text-muted">ถูกรางวัล</div>
            <div className="col text-right font-weight-bold tx-13 text-muted">ดำเนินการ</div>
          </div>


          {
            periodLottery.length > 0 ? (
              periodLottery.map(period => {
                return <RenderPeriod key={period.id} period={period} onClickEdit={(val: IPeriodLottery) => setModalFromPeriod({ mode: "edit", open: true, id: val.id })} />
              })
            ) : (
              <Card className="mb-2">
                <CardBody>
                  <div className="text-center">ไม่พบรายการ</div>
                </CardBody>
              </Card>
            )
          }

        </div>
      </div >

      <Pagination
        active={meta.page}
        limit={meta.limit}
        onPageChange={(page) => {
          setLoading(true);
          filterPeriodLottery(page, meta.limit);
        }}
        pageRangeDisplayed={5}
        total={meta.totalCount}
      />


      <FormPeriodLotteryModal
        mode={modalFormPeriod.mode as "new" | "edit"}
        open={modalFormPeriod.open}
        id={modalFormPeriod.id}
        toggle={() => {
          setModalFromPeriod({ mode: "", open: false, id: "" })
        }}
        onSubmit={(val) => {
          const payload = {
            closeDatetime: moment(`${val.closeDate}T${val.closeTime}`).utc().toISOString(),
            openDatetime: moment(`${val.openDate}T${val.openTime}`).utc().toISOString(),
            period: {
              day: moment(val.period).date(),
              month: moment(val.period).month() + 1,
              year: moment(val.period).year(),
            },
            status: val.status,
          }

          if (modalFormPeriod.mode === "new") {
            createPeriodLottery(payload);
          } else if (modalFormPeriod.mode === "edit") {
            updatePeriodLotteryById(modalFormPeriod.id, payload);
          }
        }}
      />
    </>
  )
}
export default Lottery;
