import { Fragment, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { PageLoading } from "./loader";

import Sidebar from "../component/sidebar";
import Topbar from "../component/topbar";
import Dashboard from "../page/dashboard/dashboard";
import News from "../page/news";
import NewsForm from "../page/news/news-form";
import NewsList from "../page/news/news-list";
import User from "../page/user";
import UserList from "../page/user/user-list";
import SettingAdmin from "../page/setting/setting-admin";
import RenderNews from "../page/news/news";
import Setting from "../page/setting";
import { selectAuth } from "../redux/authentication.reducer";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Page404 from "./404";
import { getToken, onMessageListener } from "../firebase";
import {
  getBadgeOrderApi,
  getBadgeNewWithdrawApi,
  subsctibeToTopicApi,
  getBadgeOrderUpgradeApi,
} from "../api/core";
import { setBadge } from "../redux/app.reducer";
import SettingUser from "../page/setting/setting-user";
import SettingInformation from "../page/setting/setting-information";
import UserEdit from "../page/user/user-edit";
import UserBookBank from "../page/user/user-book-bank";
import Report from "../page/report";
import LotteryIndex from "../page/lottery";
import Lottery from "../page/lottery/lottery";
import LotteryAnnouncement from "../page/lottery/lottery-announcement";
import LotteryDetail from "../page/lottery/detail";
import AddLottery from "../page/lottery/detail/add-lottery";
import OrderList from "../page/order/order-list";
import Order from "../page/order";
import OrderDetail from "../page/order/order-detail";
import SettingPricing from "../page/setting/setting-pricing";
import LotteryWinner from "../page/lottery/lottery-winner";
import UserHistory from "../page/user/user-history";
import SettingCommission from "../page/setting/setting-commission";
import ProductUpgrade from "../page/product-upgrade";
import ProductList from "../page/product-upgrade/product-list";
import ProductOrder from "../page/product-upgrade/product-order";
import ProductOrderDetail from "../page/product-upgrade/product-order-detail";
import Withdraw from "../page/widthdraw";
import WithdrawRequestList from "../page/widthdraw/withdraw-request-list";
import WithdrawHistoryList from "../page/widthdraw/withdraw-history-list";
import UserIncome from "../page/user/user-income";
import UserTeam from "../page/user/user-team";
import BannerFooter from "../page/banner-footer";
import Banner from "../page/banner-footer/banner";
import Footer from "../page/banner-footer/footer";
import FooterForm from "../page/banner-footer/footer-form";
import Affiliate from "../page/affiliate";
import Menu from "../component/menu";
import SettingCoversionRatePage from "../page/setting/setting-conversion-rate";
import FCMComponent from "../component/FCMComponent";
import SettingAffiliateCommission from "../page/setting/setting-affiliate-commission";

const AppLayout = () => {
  const { rules } = useAppSelector(selectAuth);
  return (
    <Fragment>
      <FCMComponent />
      <PageLoading />
      <Topbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <Routes>
              <Route
                path="/"
                element={
                  <Navigate
                    to={`${process.env.PUBLIC_URL}/dashboard`}
                    replace
                  />
                }
              />
              {rules.dashboard && Menu.find((e) => e.id === "dashboard") && (
                <Route
                  path={`${process.env.PUBLIC_URL}/dashboard`}
                  element={<Dashboard />}
                />
              )}

              {rules.userMember && Menu.find((e) => e.id === "userMember") && (
                <Route
                  path={`${process.env.PUBLIC_URL}/users`}
                  element={<User />}
                >
                  <Route index element={<UserList />} />
                  {/* <Route path={"add"} element={<UserAdd />} /> */}
                  <Route path={"income/:id"} element={<UserIncome />} />
                  <Route path={"edit/:id"} element={<UserEdit />} />
                  <Route path={"book-bank/:id"} element={<UserBookBank />} />
                  <Route path={"history/:id"} element={<UserHistory />} />
                  <Route path={"team/:id"} element={<UserTeam />} />
                </Route>
              )}

              {rules.order && Menu.find((e) => e.id === "order") && (
                <Route
                  path={`${process.env.PUBLIC_URL}/orders`}
                  element={<Order />}
                >
                  <Route index element={<OrderList />} />
                  <Route path={":id"} element={<OrderDetail />} />
                </Route>
              )}

              {rules.productsUpgrade &&
                Menu.find((e) => e.id === "productsUpgrade") && (
                  <Route
                    path={`${process.env.PUBLIC_URL}/products-upgrade`}
                    element={<ProductUpgrade />}
                  >
                    <Route index element={<ProductList />} />
                    <Route path={"orders"} element={<ProductOrder />} />
                    <Route
                      path={"orders/:id"}
                      element={<ProductOrderDetail />}
                    />
                  </Route>
                )}

              {rules.report && Menu.find((e) => e.id === "report") && (
                <Route
                  path={`${process.env.PUBLIC_URL}/report`}
                  element={<Report />}
                ></Route>
              )}

              {rules.withdraw && Menu.find((e) => e.id === "withdraw") && (
                <Route
                  path={`${process.env.PUBLIC_URL}/withdraw`}
                  element={<Withdraw />}
                >
                  <Route index element={<WithdrawRequestList />} />
                  <Route path="history" element={<WithdrawHistoryList />} />
                </Route>
              )}

              {rules.lottery && Menu.find((e) => e.id === "lottery") && (
                <Route
                  path={`${process.env.PUBLIC_URL}/lottery`}
                  element={<LotteryIndex />}
                >
                  <Route index element={<Lottery />} />
                  <Route
                    path="announcement/:id"
                    element={<LotteryAnnouncement />}
                  />
                  <Route path="winner/:id" element={<LotteryWinner />} />
                  <Route path="detail/:id" element={<LotteryDetail />} />
                  <Route path="detail/:id/add" element={<AddLottery />} />
                </Route>
              )}

              {rules.news && Menu.find((e) => e.id === "news") && (
                <Route
                  path={`${process.env.PUBLIC_URL}/news`}
                  element={<News />}
                >
                  <Route index element={<NewsList />} />
                  <Route path={`add`} element={<NewsForm mode="new" />} />
                  <Route path={`read/:id`} element={<RenderNews />} />
                  <Route path={`edit/:id`} element={<NewsForm mode="edit" />} />
                </Route>
              )}

              {rules.banner && Menu.find((e) => e.id === "banner") && (
                <Route
                  path={`${process.env.PUBLIC_URL}/banners`}
                  element={<BannerFooter />}
                >
                  <Route index element={<Banner />} />
                  <Route
                    path={`footer/add`}
                    element={<FooterForm mode="new" />}
                  />
                  <Route
                    path={`footer/edit/:id`}
                    element={<FooterForm mode="edit" />}
                  />
                  <Route path={`footer`} element={<Footer />} />
                </Route>
              )}

              {rules.affiliate && Menu.find((e) => e.id === "affiliate") && (
                <Route
                  path={`${process.env.PUBLIC_URL}/affiliate`}
                  element={<Affiliate />}
                >
                  <Route index element={<Affiliate />} />
                </Route>
              )}

              {rules.setting && Menu.find((e) => e.id === "setting") && (
                <Route
                  path={`${process.env.PUBLIC_URL}/setting`}
                  element={<Setting />}
                >
                  <Route index element={<SettingInformation />} />
                  <Route path={`admin`} element={<SettingAdmin />} />
                  <Route path={`pricing`} element={<SettingPricing />} />
                  {/* <Route
                    path={`convertion-rate`}
                    element={<SettingCoversionRatePage />}
                  /> */}
                  <Route
                    path={`affiliate-commission`}
                    element={<SettingAffiliateCommission />}
                  />
                  {/* <Route
                    path={`commission-config`}
                    element={<SettingCommission />}
                  /> */}
                </Route>
              )}

              <Route
                path={`${process.env.PUBLIC_URL}/profile`}
                element={<SettingUser />}
              ></Route>

              {/* route not found */}
              <Route path="*" element={<Page404 />} />
            </Routes>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppLayout;
