import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IConfirm, selectApp, setHideAppConfirm, setShowAppConfirm } from './app.reducer';
import type { RootState, AppDispatch } from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

let resolveCallback: (value: boolean | PromiseLike<boolean>) => void
export const useAppConfirm = () => {
  const dispatch = useAppDispatch();
  const app = useAppSelector(selectApp);


  const onConfirm = () => {
    closeConfirm();
    resolveCallback(true);
  };

  const onCancel = () => {
    closeConfirm();
    resolveCallback(false);
  };

  const confirm = (payload: IConfirm): Promise<boolean> => {
    dispatch(setShowAppConfirm({ ...payload, show: true }));
    return new Promise((resolve, rej) => {
      resolveCallback = resolve;
    });
  };

  const closeConfirm = () => {
    dispatch(setHideAppConfirm());
  };

  return { confirm, onConfirm, onCancel, confirmState: app.confirm };
}
