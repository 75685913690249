import moment from "moment";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getPeriodLotteryByIdApi } from "../../../api/lottery";
import Breadcrumb from "../../../component/breadcrumb";
import { IPeriodLottery } from "../../../interface/lottery";
import { setAppLoading } from "../../../redux/app.reducer";
import { useAppDispatch } from "../../../redux/hooks";
import useSnackbar from "../../../utils/snackbar";

const $ = require('jquery');
$.dmUploader = require("dm-file-uploader");

interface IRenderFilePreview {
  id: string;
  file: File;
}
const RenderFilePreview = ({ id, file }: IRenderFilePreview) => {
  return (`
    <li class="media" id="${id}">
      <img class="mr-3 mb-2 preview-img" src="${URL.createObjectURL(file)}" alt="" />
      <div class="media-body mb-1">
        <p class="mb-2">
          <strong>${file.name}</strong> - Status: <span class="text-muted">Waiting</span>
        </p>
        <div class="progress mb-2">
          <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" 
            role="progressbar"
            style="width: 0%" 
            aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
          </div>
        </div>
        <div class="controls mb-2">
          <button class="btn btn-sm btn-primary start" role="button">Start</button>
          <button class="btn btn-sm btn-outline-warning cancel ml-2" role="button" disabled="disabled">Cancel</button>
        </div>
        <hr class="mt-1 mb-1" />
      </div>
    </li>
  `)
}
const AddLottery = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dmUploader = useRef<HTMLDivElement>(null);
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(false);
  const [periodLottery, setPeriodLottery] = useState<IPeriodLottery>();

  const initData = async () => {
    try {
      setLoading(true);

      const period = await getPeriodLotteryByIdApi(id!);
      setPeriodLottery(period.data)

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/lottery`)
    }
  }

  const uiAddFile = (id: string, file: File) => {
    // Creates a new file and add it to our list
    $('#files').find('li.empty').fadeOut(); // remove the 'no files yet'

    $('#files').prepend(RenderFilePreview({ file, id }));
  }

  const uiUpdateFileStatus = (id: string, status: string, message: string) => {
    // Changes the status messages on our list
    $('#' + id).find('span').html(message).prop('class', 'status text-' + status);
  }

  const uiUpdateFileProgress = (id: string, percent: number, color?: string, active?: boolean) => {
    // Updates a file progress, depending on the parameters it may animate it or change the color.
    color = (typeof color === 'undefined' ? '' : color);
    active = (typeof active === 'undefined' ? true : active);

    var bar = $('#' + id).find('div.progress-bar');

    bar.width(percent + '%').attr('aria-valuenow', percent);
    bar.toggleClass('progress-bar-striped progress-bar-animated', active);

    if (percent === 0) {
      bar.html('');
    } else {
      bar.html(percent + '%');
    }

    if (color !== '') {
      bar.removeClass('bg-success bg-info bg-warning bg-danger');
      bar.addClass('bg-' + color);
    }
  }

  const uiUpdateFileControls = (id: string, start: boolean, cancel: boolean, wasError?: boolean) => {
    // Toggles the disabled status of Star/Cancel buttons on one particual file
    wasError = (typeof wasError === 'undefined' ? false : wasError);

    $('#' + id).find('button.start').prop('disabled', !start);
    $('#' + id).find('button.cancel').prop('disabled', !cancel);

    if (!start && !cancel) {
      $('#' + id).find('.controls').fadeOut();
    } else {
      $('#' + id).find('.controls').fadeIn();
    }

    if (wasError) {
      $('#' + id).find('button.start').html('Retry');
    }
  }


  const initDmUploader = () => {
    $("#dm-up-loader").dmUploader({
      url: `${process.env.REACT_APP_HOST}/v1/admin/lottery/upload`,
      queue: false,
      auto: false,
      fieldName: "file",
      allowedTypes: "image/*",
      extFilter: ["jpg", "jpeg", "png"],
      extraData: {
        periodId: id,
      },
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
      },
      onNewFile: uiAddFile,
      onBeforeUpload: function (this: any, id: string) {
        uiUpdateFileStatus(id, 'uploading', 'กำลังอัพโหลด...');
        uiUpdateFileProgress(id, 0, '', true);
        uiUpdateFileControls(id, false, true);
      },
      onUploadCanceled: function (id) {
        uiUpdateFileStatus(id, 'warning', 'ยกเลิก');
        uiUpdateFileProgress(id, 0, 'warning', false);
        uiUpdateFileControls(id, true, false);
      },
      onUploadProgress: function (id, percent) {
        uiUpdateFileProgress(id, percent, "", true);
      },
      onUploadSuccess: function (id, data) {
        uiUpdateFileStatus(id, 'success', 'อัพโหลดสำเร็จ');
        uiUpdateFileProgress(id, 100, 'success', false);
        uiUpdateFileControls(id, false, false);
      },
      onUploadError: async function (id, xhr, status, message) {
        let msg = xhr?.responseJSON?.message ?? "Error"
        uiUpdateFileStatus(id, 'danger', msg);
        uiUpdateFileProgress(id, 0, 'danger', false);
        uiUpdateFileControls(id, true, false, true);
      },
    });

    $(document).on("click", 'button.start', function (this: any) {
      const id = $(this).closest("li").attr("id");
      $('#dm-up-loader').dmUploader('start', id);
    })

    $(document).on("click", 'button.cancel', function (this: any) {
      const id = $(this).closest("li").attr("id");
      $('#dm-up-loader').dmUploader('cancel', id);
    })
  }
  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    initData();
    initDmUploader();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
  }, [dmUploader])

  return (
    <>
      <Breadcrumb
        title={`เพิ่มลอตเตอรี่ งวดประจำวันที่ ${!!periodLottery ? `${periodLottery!.period.day} ${moment.months(periodLottery!.period.month - 1)} ${(periodLottery!.period.year + 543)}` : ""}`}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "งวดลอตเตอรี่", link: "/lottery" },
          { title: `${!!periodLottery ? `${periodLottery!.period.day} ${moment.months(periodLottery!.period.month - 1)} ${(periodLottery!.period.year + 543)}` : ""}`, link: `/lottery/detail/${id}` },
          { title: `เพิ่มลอตเตอรี่` },
        ]}
      />

      <div className="row">

        <div className="col-12 mb-2">
          <div id="dm-up-loader" className="dm-uploader" style={{ background: "#F8F8F8" }}>
            <div className="card-body text-center">
              <div className="p-5">
                <span className="fa fa-cloud-upload text-muted" style={{ fontSize: 60 }}></span>
                <h3 className="mb-3 mt-2 text-muted">Drag &amp; drop files here</h3>
                <div className="btn btn-outline-primary">
                  Open the file Browser
                  <input type="file" />
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="col-12">
          <div className="card h-100">
            <div className="card-header pb-2 mb-2">
              <div className="row ">
                <div className="col">File List</div>
                <div className="col-auto ml-auto">
                  <button className="btn btn-primary" onClick={() => {
                    $('#dm-up-loader').dmUploader('start');
                  }}>
                    <i className="fa fa-play mr-2"></i> Start Upload
                  </button>
                  <button className="btn ml-2 btn-outline-primary" onClick={() => {
                    $('#dm-up-loader').dmUploader('cancel');
                  }}>
                    <i className="fa fa-stop mr-2"></i> Stop
                  </button>
                </div>
              </div>
            </div>
            {/* <ul className="list-unstyled p-2 d-flex flex-column col">
              <li className="text-muted text-center empty">No files uploaded.</li>
            </ul> */}
            <aside>
              <ul className="list-unstyled p-2 d-flex flex-column col" id="files">
                <li className="text-muted text-center empty">No files uploaded.</li>
              </ul>
            </aside>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddLottery;