import { Outlet } from "react-router-dom";

const ProductUpgrade = () => {
  return (
    <>
      <Outlet />
    </>
  )
}
export default ProductUpgrade;
