import { useEffect } from "react";
import { Link } from "react-router-dom";
import { logoutApi } from "../api/auth";
import { unsubsctibeFromTopicApi } from "../api/core";
import { selectAuth } from "../redux/authentication.reducer";
import { useAppSelector } from "../redux/hooks";
import { getSrcImage } from "../utils/utils";

const $ = require("jquery");

const Topbar = () => {
  const { user } = useAppSelector(selectAuth);

  const logout = async () => {
    try {
      let fcmToken = await localStorage.getItem("fcm_token");
      if (!!fcmToken) {
        await unsubsctibeFromTopicApi(fcmToken);
      }

      await logoutApi();
    } finally {
      localStorage.removeItem("token_type");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("expires_at");
      localStorage.removeItem("fcm_token");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
  };

  useEffect(() => {
    $('[data-toggle="offcanvas"]').on("click", function () {
      $(".sidebar-offcanvas").toggleClass("active");
    });
  }, []);
  return (
    <>
      <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center">
          <Link className="navbar-brand brand-logo" to={"/"}>
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.svg`}
              alt="logo"
              style={{
                width: 48,
              }}
            />
          </Link>
          <Link className="navbar-brand navbar-brand brand-logo-mini" to={"/"}>
            <img
              src={`${process.env.PUBLIC_URL}/images/logo-mini.svg`}
              alt="logo"
            />
          </Link>
        </div>

        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile dropdown">
              <div
                className="cursor-pointer nav-link"
                data-toggle="dropdown"
                id="profileDropdown"
              >
                <img
                  src={getSrcImage(user.picture)}
                  alt="profile"
                  style={{ width: 30, height: 30 }}
                />
              </div>
              <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown"
                aria-labelledby="profileDropdown"
              >
                <Link to="profile" className="dropdown-item">
                  <i className="mdi mdi-settings "></i>
                  ประวัติส่วนตัว
                </Link>
                <div className="dropdown-item" onClick={() => logout()}>
                  <i className="mdi mdi-logout"></i>
                  ออกจากระบบ
                </div>
              </div>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
      {/* 
      <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="container-fluid">

          <Link className="" to={"/"} >
            <img style={{ width: 150, height: 50 }} src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="logo" />
          </Link>

          <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <ul className="navbar-nav navbar-nav-right">
              <li className="nav-item nav-profile dropdown">
                <button
                  className="nav-link"
                  data-toggle="dropdown"
                  type="button"
                  style={{
                    boxShadow: "none",
                    border: "none",
                    background: "transparent",
                  }}
                >
                  <img src={getSrcImage(user.picture)} alt="profile" style={{ width: 40, height: 40 }} />
                </button>
                <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                  <Link to="profile" className="dropdown-item">
                    <i className="mdi mdi-settings "></i>
                    Settings
                  </Link>
                  <div className="dropdown-item" onClick={() => logout()}>
                    <i className="mdi mdi-logout"></i>
                    Logout
                  </div>
                </div>
              </li>
            </ul>
            <button onClick={toggleNavbar} className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button">
              <span className="mdi mdi-menu"></span>
            </button>
            <Offcanvas isOpen={!menuCollapsed} toggle={toggleNavbar}>
              <div className="mr-4 mt-4">
                <button type="button" onClick={toggleNavbar} className="close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <OffcanvasBody>
                {
                  Menu.map((e) => {
                    if (!rules[e.id]) {
                      return null;
                    }
                    return (
                      <div
                        key={e.link}
                        style={{
                          marginBottom: 10
                        }}
                        onClick={() => {
                          toggleNavbar();
                          navigate(`${process.env.PUBLIC_URL}/${e.link}`);
                        }}
                      >
                        <i className={e.icon}></i>
                        <span className="menu-title">
                          {e.title}
                          {
                            !!e.badgeKey && badge[e.badgeKey] > 0 && (
                              <span className="badge badge-pill badge-danger ml-1">{badge[e.badgeKey]}</span>
                            )
                          }
                        </span>
                      </div>
                    )
                  }).filter(e => !!e)
                }
              </OffcanvasBody>
            </Offcanvas>
          </div>
        </div >
      </nav > */}
    </>
  );
};

export default Topbar;
