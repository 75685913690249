import { SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { getSetting, updateSetting } from "../../api/setting";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { setAppLoading } from "../../redux/app.reducer";
import useSnackbar from "../../utils/snackbar";

const SettingAffiliateCommission = () => {
  const { data, isLoading, refetch } = useQuery("fetchSettings", async () => {
    return (await getSetting()).data;
  });
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const { register, handleSubmit, setValue } = useForm<{
    commissionPercentage: number;
  }>();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<{ commissionPercentage: number }> = async ({
    commissionPercentage,
  }) => {
    if (!data) return;
    setLoading(true);
    try {
      await updateSetting({
        conversionRate: data.conversionRate,
        commissionPercentage,
      });
      setLoading(false);
      refetch();
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    if (data) {
      setValue("commissionPercentage", data.commissionPercentage);
    }
  }, [data]);

  useEffect(() => {
    dispatch(setAppLoading(isLoading || loading));
  }, [isLoading, loading]);

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset>
                <legend>ค่าคอมมิชชั่น</legend>
                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label font-weight-bold"
                  >
                    ส่วนแบ่งรายได้
                  </label>
                  <div className="col-sm-4">
                    <input
                      {...register("commissionPercentage", {
                        required: true,
                        valueAsNumber: true,
                      })}
                      type="number"
                      className="form-control"
                      placeholder="กรอกค่าคอมมิชชั่นเป็นจำนวนเต็มบวก"
                      step={1}
                      min={1}
                    />
                  </div>
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label font-weight-bold"
                  >
                    % จากยอดขาย
                  </label>
                </div>
              </fieldset>
              <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-4 text-right">
                  <button className="btn btn-primary" type="submit">
                    บันทึก
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingAffiliateCommission;
