import moment from 'moment';
import { ReactNode, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useNavigate, useParams } from 'react-router-dom';
import { getOrderLogsByOrderIdApi } from '../../api/order';
import { confirmOrderUpgradeApi, getOrderUpgradeByIdApi, updateShippingOrderUpgradeApi } from '../../api/order_upgrade';
import { getProfileUserByApi } from '../../api/user';
import Breadcrumb from '../../component/breadcrumb';
import ModalViewImage from '../../component/modal-view-image';
import { IOrderLogs } from '../../interface/order';
import { IOrderUpgrade } from '../../interface/order_upgrade';
import { IUser } from '../../interface/user';
import { setAppLoading } from '../../redux/app.reducer';
import { useAppDispatch } from '../../redux/hooks';
import useSnackbar from '../../utils/snackbar';
import { getSrcImage } from '../../utils/utils';

const ProductOrderDetail = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<IOrderUpgrade>();
  const [orderLogs, setOrderLogs] = useState<IOrderLogs[]>([]);
  const [user, setUser] = useState<IUser>();
  const [selectedAction, setSelectedAction] = useState<number>(0);
  const [shipping, setShipping] = useState<{ carrier: string, shippingDate?: Date, trackingNumber: string }>({
    carrier: "",
    shippingDate: undefined,
    trackingNumber: "",
  });

  const getStatus = (status?: number): ReactNode => {
    switch (status) {
      case 0:
        return (
          <span className="text-danger">ยกเลิก</span>
        );
      case 1:
        return (
          <span className="text-success">สำเร็จ</span>
        );
      case 2:
        return (
          <span className="text-warning">รอยืนยัน</span>
        );
      case 3:
        return (
          <span className="text-info">ที่ต้องจัดส่ง</span>
        );
      default:
        return (
          <span className="text-light">-</span>
        );
    }
  }

  const getOrderById = async (): Promise<IOrderUpgrade> => {
    try {
      const { data } = await getOrderUpgradeByIdApi(id!);
      setOrder(data);
      setShipping({
        carrier: data.shippingCarrier ?? "",
        shippingDate: !!data.shippingDate ? new Date(data.shippingDate) : undefined,
        trackingNumber: data.trackingNumber ?? "",
      })
      return data;
    } catch (e: any) {
      throw e;
    }
  }

  const getProfileUserById = async (uid: string) => {
    try {
      const { data } = await getProfileUserByApi(uid);
      setUser(data)
    } catch (e: any) {
      throw e;
    }
  }

  const getOrderLogsByOrderId = async (id: string) => {
    try {
      const { data } = await getOrderLogsByOrderIdApi(id);
      setOrderLogs(data ?? [])
    } catch (e: any) {
      throw e;
    }
  }

  const initData = async () => {
    try {
      setLoading(true);
      const order = await getOrderById();
      await Promise.all([
        getOrderLogsByOrderId(order.id),
        getProfileUserById(order.userId)
      ])

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const confirmOrderUpgrade = async (id: string, status: 0 | 1 | 2 | 3) => {
    try {
      setLoading(true);
      await confirmOrderUpgradeApi({ id, status });
      const order = await getOrderById();
      await Promise.all([
        getOrderLogsByOrderId(order.id),
        getProfileUserById(order.userId)
      ])
      openSnackbar("Success");
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const updateShippingOrderUpgrade = async (payload: {
    id: string,
    shippingDate: number,
    shippingCarrier: string,
    trackingNumber: string,
  }) => {
    try {
      setLoading(true);
      await updateShippingOrderUpgradeApi(payload);
      openSnackbar("Success");
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <Breadcrumb
        title={"รายละเอียด"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สินค้าอัพเกรด", link: "/products-upgrade/orders" },
          { title: "รายละเอียด" }
        ]}
      />
      <h4 className="mb-2">หมายเลขคำสั่งซื้อ {order?.orderNo ?? ""} {getStatus(order?.status)} </h4>
      <div className="row mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col">
                      <span className="text-muted tx-13">ผู้ซื้อ</span>
                      <br />
                      <div className='hover-underline cursor-pointer' onClick={() => navigate(`${process.env.PUBLIC_URL}/users/edit/${order?.userId}`)}>{user?.firstName} {user?.lastName}</div>
                      <span className="tx-13 text-muted">รหัส {user?.userNo}</span>
                    </div>
                    <div className="col"><span className="text-muted tx-13">เบอร์โทรศัพท์</span><br />{user?.phoneNumber}</div>
                    <div className="col"><span className="text-muted tx-13">สถานะ</span><br />{!!user ? (user?.status === 1 ? "ใช้งาน" : "ระงับ") : "-"}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">การจัดส่ง</h6>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col">
                      <div className="text-muted tx-13">ชื่อ</div>
                      {order?.shippingName ?? ""}
                    </div>
                    <div className="col">
                      <div className="text-muted tx-13">เบอร์โทรศัพท์</div>
                      {order?.shippingPhone ?? ""}
                    </div>
                    <div className="col">
                      <div className="text-muted tx-13">ที่อยู่</div>
                      {order?.shippingAddress ?? ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">ขนส่ง</h6>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="row justify-content-center">
                    <div className="col">
                      <div className="text-muted tx-13">ขนส่ง</div>
                      <select className="form-control" value={shipping.carrier} onChange={(e) => {
                        setShipping(prev => ({
                          ...prev,
                          carrier: e.target.value,
                        }))
                      }}>
                        <option value="Kerry">Kerry</option>
                        <option value="Flash">Flash</option>
                        <option value="ไปรษณีไทย">ไปรษณีไทย</option>
                        <option value="นินจา">นินจา</option>
                      </select>
                    </div>
                    <div className="col">
                      <div className="text-muted tx-13">วันที่</div>
                      <div className="input-group input-daterange d-flex align-items-center">
                        <ReactDatePicker
                          selected={shipping.shippingDate}
                          onChange={(date) => {
                            setShipping(prev => ({
                              ...prev,
                              shippingDate: date ?? undefined,
                            }))
                          }}
                          showTimeSelect
                          timeFormat="HH:mm"
                          isClearable={true}
                          customInput={
                            <input className="form-control" />
                          }
                          dateFormat="dd/MM/yyyy HH:mm"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="text-muted tx-13">Tracking ID</div>
                      <input type="text" className="form-control" placeholder="Tracking" value={shipping.trackingNumber} onChange={(e) => {
                        setShipping(prev => ({
                          ...prev,
                          trackingNumber: e.target.value,
                        }))
                      }} />
                    </div>
                    <div className="d-flex justify-content-end flex-column" style={{ paddingBottom: 4 }}>
                      <button className="btn btn-success ml-auto" onClick={() => {
                        updateShippingOrderUpgrade({
                          id: id!,
                          shippingCarrier: shipping.carrier,
                          shippingDate: Math.floor((shipping.shippingDate?.valueOf() ?? 0) / 1000),
                          trackingNumber: shipping.trackingNumber,
                        })
                      }}>บันทึก</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">สถานะคำสั่งซื้อ</h6>
          <div className="card">
            <div className="card-body">
              <table className="table">
                <thead>
                  <tr>
                    <th>สถานะ</th>
                    <th>วันที่</th>
                    <th>เวลา</th>
                    <th>โดย</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    orderLogs.length > 0 ? orderLogs.map(logs => {
                      return (
                        <tr key={logs.id}>
                          <td className="name">{logs.remark}</td>
                          <td className="unit">{moment(logs.createdAt).format("DD/MM/yyyy")}</td>
                          <td className="price">{moment(logs.createdAt).format("hh:mm:ss")}</td>
                          <td>{logs.createdBy}</td>
                        </tr>
                      )
                    }) : (
                      <tr>
                        <td colSpan={4} className="text-center">
                          ไม่พบรายการ
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <div className="content">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: 50 }}></th>
                        <th>สินค้า</th>
                        <th>ราคา</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td><span className="h5">{order?.productName}</span></td>
                        <td>฿{order?.productPrice.toLocaleString('en-US')}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row text-right">
                <div className="col-12 border-bottom border-top pt-4 pb-4 "><span className="h4">ราคาทั้งหมด ฿{order?.productPrice.toLocaleString("en-US") ?? 0}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        order?.paymentMethod === "โอนเงิน" && (
          <div className="row mb-4">
            <div className="col-12 ">
              <h6 className="text-muted">หลักฐานการชำระเงิน</h6>
              <div className="card ">
                <div className="card-body">
                  <div className="col-lg-6 m-auto">
                    <div className="card">
                      <div className="card-body text-center cursor-pointer" onClick={() => {
                        setShowImage({
                          show: true,
                          src: getSrcImage(order?.slip),
                        })
                      }}>
                        <img src={getSrcImage(order?.slip)} alt="" style={{
                          height: 500,
                          width: "auto",
                          objectFit: "contain"
                        }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        [2, 3].includes(order?.status ?? 0) && (
          <div className="row mb-4">
            <div className="col-12">
              <div className="card ">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <select className="form-control" value={""} onChange={(e) => {
                        setSelectedAction(+e.target.value)
                      }}>
                        {
                          order?.status === 3 && (<option value="1">จัดส่งสำเร็จ</option>)
                        }
                        {
                          order?.status === 2 && (
                            <>
                              <option value="3">ยืนยัน</option>
                              <option value="0">ยกเลิก</option>
                            </>
                          )
                        }
                      </select>
                    </div>
                    <button className="btn btn-success ml-auto mr-2" onClick={() => {
                      confirmOrderUpgrade(order?.id ?? "", selectedAction as 0 | 1 | 2 | 3)
                    }}>บันทึก</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage(prev => ({
            ...prev,
            show: false,
          }))
        }}
      />
    </>
  )
}

export default ProductOrderDetail;
