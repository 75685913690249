import { FC, useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { ILottery, IUpdateLotteryReq } from "../../../interface/lottery";

interface IEditLotteryModalModal {
  open: boolean;
  data: ILottery;
  toggle: () => void;
  onSubmit: (id: string, payload: IUpdateLotteryReq) => void;
}

const EditLotteryModal: FC<IEditLotteryModalModal> = ({ open, data, toggle, onSubmit }) => {
  const [payload, setPayload] = useState<IUpdateLotteryReq>({
    number: data.number,
    yearPeriodSet: data.yearPeriodSet,
  });

  useEffect(() => {
    setPayload({
      number: data?.number ?? "",
      yearPeriodSet: data?.yearPeriodSet ?? "",
    })
  }, [data])

  return (
    <>
      <Modal size="md" isOpen={open} toggle={toggle} centered={false}>
        <div className="modal-title modal-header">
          <h5 className="modal-title">แก้ไขลอตเตอรี่</h5>
          <button type="button" onClick={toggle} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit(data.id, payload)
            }}
          >
            <div className="container">
              <div className="form-group">
                <label htmlFor="" className="required">ปี-งวด-ชุดที่</label>
                <input
                  type="text"
                  required
                  pattern="^\d{2}-\d{2}-\d{2}$"
                  className="form-control"
                  value={payload.yearPeriodSet}
                  onChange={(e) => {
                    setPayload(prev => ({
                      ...prev,
                      yearPeriodSet: e.target.value,
                    }))
                  }}
                />
              </div>


              <div className="form-group">
                <label htmlFor="" className="required">หมายเลขสลาก</label>
                <input
                  type="text"
                  required
                  pattern="^\d{6}$"
                  className="form-control"
                  value={payload.number}
                  onChange={(e) => {
                    setPayload(prev => ({
                      ...prev,
                      number: e.target.value,
                    }))
                  }}
                />
              </div>

            </div>
            <div className="form-group text-right">
              <button type="submit" className="btn btn-success mr-2">บันทึก</button>
              <button type="button" className="btn btn-light" onClick={toggle}>ยกเลิก</button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default EditLotteryModal;