import { AxiosResponse } from "axios";
import { ICreateProduct, IProduct, IUpdateProduct } from "../interface/product";
import { http } from "../utils/http";

export const filterProductApi = async (payload: {
  status?: string;
  page: number;
  limit: number;
}) => {
  return await http.get("/v1/admin/products/filter", payload)
};

export const createProductApi = async (payload: ICreateProduct) => {
  return await http.post("/v1/admin/products", payload)
};

export const deleteProductByIdApi = async (id: string): Promise<AxiosResponse<void>> => {
  return await http.del(`/v1/admin/products/${id}`)
};

export const getProductByIdApi = async(id: string): Promise<AxiosResponse<IProduct>> => {
  return await http.get(`/v1/admin/products/${id}`)
};

export const updateProductByIdApi = async (id: string, payload: IUpdateProduct) => {
  return await http.put(`/v1/admin/products/${id}`, payload)
};