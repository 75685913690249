import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getProfileUserByApi } from "../../api/user";
import Breadcrumb from "../../component/breadcrumb";
import { IUser } from "../../interface/user";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";

const UserTeam = () => {
  let { id } = useParams();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<IUser>();

  const getProfileUserById = async () => {
    try {
      setLoading(true);
      const { data } = await getProfileUserByApi(id!);
      setUser(data);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/users`);
    }
  };

  useEffect(() => {
    getProfileUserById();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      <Breadcrumb
        title={"สมาชิก"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก", link: "/users" },
          { title: `${user?.firstName ?? ""} ${user?.lastName ?? ""}` },
          { title: "ทีมงาน" },
        ]}
      />

      <div className="row">
        <div className="col-12 m-0">
          <div className="row">
            <div className="col">
              <ul
                className="nav nav-pills nav-fill"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">ประวัติส่วนตัว</span> */}
                  <Link className="nav-link" to={`/users/edit/${id}`}>
                    ประวัติส่วนตัว
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">รายได้</span> */}
                  <Link className="nav-link" to={`/users/income/${id}`}>
                    รายได้
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">ประวิติการใช้งาน</span> */}
                  <Link className="nav-link" to={`/users/history/${id}`}>
                    ประวิติการใช้งาน
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">บัญชีรับรางวัล</span> */}
                  <Link className="nav-link" to={`/users/book-bank/${id}`}>
                    บัญชีรับรางวัล
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <span className="nav-link active">ทีมงาน</span>
                  {/* <Link className="nav-link" to={`/users/team/${id}`}>ทีมงาน</Link> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <iframe
                title="hierarchy"
                src={`${process.env.PUBLIC_URL}/team.html?u=${JSON.stringify(
                  user
                )}`}
                width={"100%"}
                height={600}
                style={{ border: 0 }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTeam;
