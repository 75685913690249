import { FC, useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { createPricingApi, deletePricingApi, getPricingApi, updatePricingApi } from "../../api/core";
import { IPricing } from "../../interface/pricing";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppConfirm, useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";

const SettingPricing = () => {
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const { confirm } = useAppConfirm();
  const [pricing, setPricing] = useState<IPricing[]>([]);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState<{ mode: "new" | "edit", show: boolean, data?: IPricing }>({
    mode: "new",
    show: false,
  });

  const getPricing = async () => {
    try {
      setLoading(true);

      const { data } = await getPricingApi();
      setPricing(data)

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const createPricing = async (payload: IPricing) => {
    try {
      setLoading(true);

      await createPricingApi(payload);
      const { data } = await getPricingApi();
      setPricing(data)
      setOpenForm(prev => ({ ...prev, show: false, data: undefined }))

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const updatePricing = async (payload: IPricing) => {
    try {
      setLoading(true);
      await updatePricingApi(payload);
      const { data } = await getPricingApi();
      setPricing(data)
      setOpenForm(prev => ({ ...prev, show: false, data: undefined }))

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const onDelete = async (id: number) => {
    try {
      const isConfirmed = await confirm({
        title: "ยืนยันการทำรายการ",
        description: "ยืนยันการลบข้อมูล",
        textCancel: "ยกเลิก",
        classBtnCancel: "btn btn-light",
        textConfirm: "ยืนยัน",
        classBtnConfirm: "btn btn-danger",
      });

      if (!isConfirmed) return;

      setLoading(true);
      await deletePricingApi(id);
      const { data } = await getPricingApi();
      setPricing(data)

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    getPricing();
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="row mb-4">
        <div className="col-12">
          <div className="card-">
            <div className="card-body-">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header ">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>การตั้งราคา</h4>
                        <button onClick={() => { setOpenForm(prev => ({ ...prev, mode: "new", show: true })) }} className="btn btn-primary">+ เพิ่มรายการ</button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <div className="content">
                          <table id="example" className="table">
                            <thead>
                              <tr>
                                <th>ชุด</th>
                                <th>ราคา/ใบ(บาท)</th>
                                <th>ค่าบริการ(บาท)</th>
                                <th style={{ width: 80 }}>Edit</th>
                                <th style={{ width: 80 }}>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                pricing?.map(price => {
                                  return (
                                    <tr key={price.id}>
                                      <td className="end">{price.id}</td>
                                      <td className="cost">{price.price}</td>
                                      <td className="service">{price.serviceCharge}</td>
                                      <td className="edit">
                                        <div className="cursor-pointer" onClick={() => {
                                          setOpenForm(prev => ({
                                            ...prev,
                                            mode: "edit",
                                            show: true,
                                            data: price,
                                          }))
                                        }}>
                                          <i className="fa fa-edit tx-20 mt-1 ml-1 text-muted"></i>
                                        </div>
                                      </td>
                                      <td className="trash">
                                        <div className="cursor-pointer" onClick={() => {
                                          onDelete(price.id);
                                        }}>
                                          <i className="fa fa-trash-o tx-20 mt-1 ml-3 text-muted"></i>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormPricingModal
        mode={openForm.mode}
        show={openForm.show}
        toggle={() => {
          setOpenForm(prev => ({ ...prev, show: false, data: undefined }))
        }}
        data={openForm.data}
        onSubmit={(val) => {
          if (openForm.mode === "new") {
            createPricing(val);
          } else {
            updatePricing(val);
          }
        }}
      />
    </>
  )
}

interface IFormPricingModal {
  mode: "new" | "edit",
  show: boolean,
  data?: IPricing,
  toggle: Function,
  onSubmit: (data: IPricing) => void,
}

const FormPricingModal: FC<IFormPricingModal> = ({
  mode,
  show,
  toggle,
  data,
  onSubmit
}) => {
  const [value, setValue] = useState<IPricing>({
    id: 0,
    price: 0,
    serviceCharge: 0,
  });
  useEffect(() => {
    setValue(data ?? {
      id: 0,
      price: 0,
      serviceCharge: 0,
    })
    // eslint-disable-next-line
  }, [show])


  return (
    <Modal isOpen={show} toggle={() => toggle()} centered={false}>
      <div className="modal-title modal-header">
        <h5 className="modal-title">{mode === "new" ? "เพิ่มรายการ" : "แก้ไขรายการ"}</h5>
        <button type="button" onClick={() => toggle()} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <form autoComplete="false" onSubmit={(e) => {
          e.preventDefault();
          onSubmit(value);
        }}>
          <div>
            <div className="form-group">
              <label className="required">ชุด</label>
              <input
                required
                disabled={mode === "edit"}
                type={"text"}
                className="form-control"
                value={value?.id ?? ""}
                onChange={(e) => {
                  let val = e.target.value.replace(/\D/g, '');
                  setValue(prev => ({
                    ...prev,
                    id: +(val)
                  }))
                }}
              />
            </div>
            <div className="form-group">
              <label className="required">ราคา/ใบ(บาท)</label>
              <input
                required
                type={"text"}
                className="form-control"
                value={value?.price ?? ""}
                onChange={(e) => {
                  let val = e.target.value.replace(/\D/g, '');
                  setValue(prev => ({
                    ...prev,
                    price: +(val)
                  }))
                }}
              />
            </div>
            <div className="form-group">
              <label className="required">ค่าบริการ(บาท)</label>
              <input
                required
                type={"text"}
                className="form-control"
                value={value?.serviceCharge ?? ""}
                onChange={(e) => {
                  let val = e.target.value.replace(/\D/g, '');
                  setValue(prev => ({
                    ...prev,
                    serviceCharge: +(val)
                  }))
                }}
              />
            </div>
            <div className="row mt-3">
              <button type="button" onClick={() => toggle()} className="btn btn-light ml-auto mr-2">ยกเลิก</button>
              <button type="submit" className="btn btn-primary mr-2">บันทึกข้อมูล</button>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default SettingPricing;