import { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import Meta from "../../interface/meta";
import { selectApp, setAppLoading } from "../../redux/app.reducer";
import {
  useAppConfirm,
  useAppDispatch,
  useAppSelector,
} from "../../redux/hooks";
import Breadcrumb from "../../component/breadcrumb";
import {
  ICreateProduct,
  IProduct,
  IUpdateProduct,
} from "../../interface/product";
import {
  createProductApi,
  deleteProductByIdApi,
  filterProductApi,
  updateProductByIdApi,
} from "../../api/product";
import ModalViewImage from "../../component/modal-view-image";
import { Modal, ModalBody } from "reactstrap";
import { getSrcImage } from "../../utils/utils";
import Pagination from "../../component/pagination";

const $ = require("jquery");
$.dropify = require("dropify");

const ProductList = () => {
  const { badge } = useAppSelector(selectApp);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { confirm } = useAppConfirm();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const [modalFormProduct, setModalFromProduct] = useState<{
    mode: "new" | "edit" | "";
    open: boolean;
    data?: IProduct | undefined;
  }>({ mode: "", open: false, data: undefined });
  const [meta, setMeta] = useState<Meta>({
    page: 1,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [filter, setFilter] = useState<{ status: string }>({
    status: "",
  });
  const filterProduct = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterProductApi({
        status: filter.status,
        page,
        limit,
      });

      setProducts(data.results ?? []);
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const createProduct = async (payload: ICreateProduct) => {
    try {
      setLoading(true);
      await createProductApi(payload);
      setModalFromProduct({ mode: "", open: false, data: undefined });
      const { data } = await filterProductApi({
        status: filter.status,
        page: meta.page,
        limit: meta.limit,
      });

      setProducts(data.results ?? []);
      setMeta({
        page: meta.page,
        limit: meta.limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const updateProductById = async (id: string, payload: IUpdateProduct) => {
    try {
      if (id === "") {
        openSnackbar("ไม่สารมารถอัพเดทข้อมูลได้");
        return;
      }
      setLoading(true);
      await updateProductByIdApi(id, payload);
      setModalFromProduct({ mode: "", open: false, data: undefined });
      const { data } = await filterProductApi({
        status: filter.status,
        page: meta.page,
        limit: meta.limit,
      });

      setProducts(data.results ?? []);
      setMeta({
        page: meta.page,
        limit: meta.limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const deleteProductById = async (id: string) => {
    try {
      const isConfirmed = await confirm({
        title: "ยืนยันการทำรายการ",
        description: "ยืนยันการลบข้อมูล",
        textCancel: "ยกเลิก",
        classBtnCancel: "btn btn-light",
        textConfirm: "ยืนยัน",
        classBtnConfirm: "btn btn-danger",
      });

      if (!isConfirmed) return;

      setLoading(true);
      await deleteProductByIdApi(id);
      const { data } = await filterProductApi({
        status: filter.status,
        page: meta.page,
        limit: meta.limit,
      });

      setProducts(data.results ?? []);
      setMeta({
        page: meta.page,
        limit: meta.limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      openSnackbar("ลบข้อมูลสำเร็จ");
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    filterProduct(meta.page, meta.limit);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      <div className="dashboard-header d-flex flex-column grid-margin ">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <Breadcrumb
            title={"สินค้าอัพเกรด"}
            children={[
              { title: "หน้าแรก", link: "/" },
              { title: "สินค้าอัพเกรด" },
            ]}
          />
          <button
            className="btn btn-primary ml-auto"
            onClick={() => {
              setModalFromProduct({ mode: "new", open: true, data: undefined });
            }}
          >
            <span className="fa fa-plus mr-2"></span>เพิ่มสินค้า
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills active" id="pills-tab" role="tablist">
            <li className="nav-item mt-2">
              <Link
                className={`nav-link ${
                  !pathname.endsWith("/orders") ? "active" : ""
                }`}
                to={`${process.env.PUBLIC_URL}/products-upgrade`}
              >
                สินค้าอัพเกรด
              </Link>
            </li>
            <li className="nav-item mt-2">
              <Link
                className={`nav-link ${
                  pathname.endsWith("/orders") ? "active" : ""
                }`}
                to={`${process.env.PUBLIC_URL}/products-upgrade/orders`}
              >
                คำสั่งซื้อ
                {badge.newOrderProductsUpgrade > 0 && (
                  <span className="badge badge-pill badge-danger ml-1">
                    {badge.newOrderProductsUpgrade}
                  </span>
                )}
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4 ">
                  <span className="mr-2 ">
                    ทั้งหมด {meta.totalCount.toLocaleString("en-US")} รายการ
                  </span>
                </div>
                <div className="col-lg p-0">
                  <div className="form-row mb-2 ">
                    <div className="col-lg-2 ml-auto">
                      <div className="form-group">
                        <select
                          className="form-control w-100"
                          value={filter.status}
                          onChange={(e) => {
                            setFilter((prev) => ({
                              ...prev,
                              status: e.target.value,
                            }));
                          }}
                        >
                          <option value="">ทั้งหมด</option>
                          <option value="1">ใช้งาน</option>
                          <option value="0">ระงับ</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: 100 }}>รูป</th>
                        <th>สินค้า</th>
                        <th>ราคา</th>
                        <th>สถานะ</th>
                        <th className="text-right">ดำเนินการ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.length > 0 ? (
                        products.map((product) => {
                          return (
                            <tr key={product.id}>
                              <td>
                                <div
                                  className="cursor-pointer img-thumbmail"
                                  onClick={() => {
                                    setShowImage({
                                      src: getSrcImage(product.picture),
                                      show: true,
                                    });
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={getSrcImage(
                                      product.picture,
                                      "?tr=w-50"
                                    )}
                                  />
                                </div>
                              </td>
                              <td>{product.name}</td>
                              <td>{product.price.toLocaleString("en-US")}</td>
                              <td>
                                <span
                                  className={`badge badge-${
                                    product.status === 0 ? "danger" : "success"
                                  } h5 font-weight-bold`}
                                >
                                  {product.status === 0 ? "ระงับ" : "ใช้งาน"}
                                </span>
                              </td>
                              <td className="text-right">
                                <button
                                  className="btn btn-outline-primary mr-1 mb-1 pt-2"
                                  onClick={() => {
                                    setModalFromProduct({
                                      mode: "edit",
                                      open: true,
                                      data: product,
                                    });
                                  }}
                                >
                                  <span className="fa fa-edit "></span>
                                </button>
                                <button
                                  className="btn btn-outline-primary mb-1 pt-2"
                                  onClick={() => {
                                    deleteProductById(product.id);
                                  }}
                                >
                                  <span className="fa fa-trash-o"></span>
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5} className="text-center">
                            ไม่พบรายการ
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <Pagination
                active={meta.page}
                limit={meta.limit}
                onPageChange={(page) => {
                  filterProduct(page, meta.limit);
                }}
                pageRangeDisplayed={5}
                total={meta.totalCount}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage((prev) => ({
            ...prev,
            show: false,
          }));
        }}
      />

      <ModalFormProduct
        mode={modalFormProduct.mode as "new" | "edit"}
        open={modalFormProduct.open}
        data={modalFormProduct.data}
        toggle={() => {
          setModalFromProduct({ mode: "", open: false, data: undefined });
        }}
        onSubmit={(payload) => {
          if (modalFormProduct.mode === "new") {
            createProduct(payload);
          } else if (modalFormProduct.mode === "edit") {
            updateProductById(modalFormProduct.data?.id ?? "", payload);
          }
        }}
      />
    </>
  );
};

interface IModalFormProduct {
  mode: "new" | "edit";
  data?: IProduct | undefined;
  open: boolean;
  toggle: () => void;
  onSubmit: (payload: ICreateProduct | IUpdateProduct) => void;
}
const ModalFormProduct: FC<IModalFormProduct> = ({
  mode,
  data,
  open,
  toggle,
  onSubmit,
}) => {
  const [product, setProduct] = useState<ICreateProduct | IUpdateProduct>({
    picture: "",
    name: "",
    price: 0,
    detail: "",
    status: 1,
  });

  const initDropify = (defaultFile?: string) => {
    setTimeout(() => {
      const drEvent = $(".dropify").dropify({
        defaultFile: !!defaultFile ? getSrcImage(defaultFile) : "",
      });
      drEvent.on("dropify.afterClear", function (event, element) {
        setProduct((prev) => ({
          ...prev,
          picture: "",
        }));
      });
    }, 500);
  };

  useEffect(() => {
    initDropify(data?.picture);

    if (!open) {
      setProduct({
        picture: "",
        name: "",
        price: 0,
        detail: "",
        status: 1,
      });
      // disposeDropify();
    } else {
      if (mode === "edit") {
        setProduct((prev) => ({
          name: data?.name ?? prev.name,
          detail: data?.detail ?? prev.detail,
          picture: data?.picture ?? prev.picture,
          price: data?.price ?? prev.price,
          status: data?.status ?? prev.status,
        }));
      }
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <>
      <Modal size="lg" isOpen={open} toggle={toggle} centered={false}>
        <div className="modal-title modal-header">
          <h5 className="modal-title">
            {mode === "new" ? "เพิ่มสินค้าอัพเกรด" : "แก้ไขสินค้าอัพเกรด"}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit(product);
            }}
          >
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">อัพโหลดรูปภาพ</label>
              <div className="col-sm-8">
                <input
                  type="file"
                  onChange={(e) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(e.target.files![0]);
                    reader.onload = function () {
                      setProduct((prev) => ({
                        ...prev,
                        picture: reader.result as string,
                      }));
                    };
                  }}
                  className="dropify"
                  data-max-file-size="3M"
                  data-allowed-file-extensions="jpg png jpeg"
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="" className="col-sm-3 col-form-label required">
                ชื่อสินค้า
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  required
                  className="form-control"
                  value={product.name}
                  onChange={(e) => {
                    setProduct((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="" className="col-sm-3 col-form-label">
                รายละเอียด
              </label>
              <div className="col-sm-8">
                <textarea
                  className="form-control"
                  value={product.detail}
                  style={{
                    maxWidth: "100%",
                    minHeight: 150,
                  }}
                  onChange={(e) => {
                    setProduct((prev) => ({
                      ...prev,
                      detail: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="" className="col-sm-3 col-form-label required">
                ราคา
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  required
                  pattern="^\d+$"
                  className="form-control"
                  value={product.price}
                  onChange={(e) => {
                    if (isNaN(+e.target.value)) return;
                    setProduct((prev) => ({
                      ...prev,
                      price: +e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-3 col-form-label">สถานะ</label>
              <div className="col-lg-8">
                <select
                  className="form-control"
                  value={`${product.status}`}
                  onChange={(e) => {
                    setProduct((prev) => ({
                      ...prev,
                      status: +e.target.value,
                    }));
                  }}
                >
                  <option value="1">ใช้งาน</option>
                  <option value="0">ระงับ</option>
                </select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-11 text-right">
                <button type="submit" className="btn btn-success mr-2">
                  บันทึก
                </button>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={toggle}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ProductList;
