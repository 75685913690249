import { createPortal } from 'react-dom';
import { useAppConfirm } from '../redux/hooks';

const ConfirmDialog = () => {
  const { onConfirm, onCancel, confirmState } = useAppConfirm();

  const portalElement = document.getElementById('portal');

  const component = confirmState.show ? (
    <div className="portal-overlay">
      <div className="confirm-dialog">
        <div className={"d-flex flex-column justify-content-center align-items-center"}>
          <span style={{ fontSize: 18, fontWeight: "bold" }}>{confirmState.title}</span>
          {
            confirmState.description && (
              <span style={{ color: "#858693", fontSize: 14 }}>{confirmState.description}</span>
            )
          }
        </div>
        <div className="confirm-dialog__footer d-flex justify-content-center" style={{gap: "0.5rem"}}>
          <div className={confirmState.classBtnCancel} onClick={onCancel}>
            {confirmState.textCancel?.length > 0 ? confirmState.textCancel : "Cancel"}
          </div>
          <div className={confirmState.classBtnConfirm} onClick={onConfirm}>
            {confirmState.textConfirm?.length > 0 ? confirmState.textConfirm : "Confirm"}
          </div>
        </div>
      </div>
    </div>
  ) : null;

  return createPortal(component, portalElement!);
};
export default ConfirmDialog;
