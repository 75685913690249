import { http } from "../utils/http";
import { AxiosResponse } from "axios";
import {
  ICheckLotteryResultResponse,
  ILotterySetSummary,
  ILotteryWithPagignation,
  IPeriodLotteryWithPagignation,
  ILotteryResult,
  ILotteryResultTierUpdateRequest,
  IPeriodLottery,
  IPeriodLotteryRequest,
  IPeriodLotteryWithMainResult,
  IUpdateLotteryReq,
} from "../interface/lottery";
import { IPeriod } from "../interface/period";

export const createPeriodLotteryApi = async (
  payload: IPeriodLotteryRequest
): Promise<AxiosResponse<IPeriodLottery>> => {
  return await http.post("/v1/admin/period-lottery", payload);
};

export const updatePeriodLotteryByIdApi = async (
  id: string,
  payload: IPeriodLotteryRequest
): Promise<AxiosResponse<IPeriodLottery>> => {
  return await http.put(`/v1/admin/period-lottery/${id}`, payload);
};

export const filterPeriodLotteryApi = async (payload: {
  page: number;
  limit: number;
}): Promise<AxiosResponse<IPeriodLotteryWithPagignation>> => {
  return await http.get("/v1/admin/period-lottery/filter", payload);
};

export const getPeriodLotteryActiveApi = async (): Promise<
  AxiosResponse<IPeriodLottery | null>
> => {
  return await http.get(`/v1/period-lottery/active`);
};

export const getPeriodLotteryByIdApi = async (
  id: string
): Promise<AxiosResponse<IPeriodLottery>> => {
  return await http.get(`/v1/period-lottery/${id}`);
};

export const getPeriodLotteryByPeriodYearApi = async (
  year: number
): Promise<AxiosResponse<IPeriodLotteryWithMainResult[]>> => {
  return await http.get(`/v1/period-lottery/period-year`, { year });
};

export const getLotteryResultByIdApi = async (
  id: string
): Promise<AxiosResponse<ILotteryResult>> => {
  return await http.get(`/v1/lottery-result/${id}`);
};

export const updateLotteryResultTierByIdApi = async (
  id: string,
  payload: ILotteryResultTierUpdateRequest
): Promise<AxiosResponse<ILotteryResult>> => {
  return await http.patch(`/v1/admin/lottery-result/${id}/tier`, payload);
};

export const checkLotteryByPeriodApi = async (payload: {
  period: IPeriod;
  lotteryNumbers: string[];
}): Promise<AxiosResponse<ICheckLotteryResultResponse>> => {
  return await http.post(`/v1/lottery-result/check`, payload);
};

export const filterLotteryApi = async (payload: {
  page?: number;
  limit?: number;
  periodId: string;
  win?: string;
  totalInset?: number;
  createBegin?: number;
  createEnd?: number;
  awardTier?: string;
  number?: string;
  status?: string;
  type?: "csv";
}): Promise<AxiosResponse<ILotteryWithPagignation>> => {
  return await http.get("/v1/admin/lottery/filter", payload);
};

export const updateLotteryByIdApi = async (
  id: string,
  payload: IUpdateLotteryReq
): Promise<AxiosResponse<void>> => {
  return await http.patch(`/v1/admin/lottery/${id}`, payload);
};

export const deleteLotteryByIdApi = async (
  id: string
): Promise<AxiosResponse<void>> => {
  return await http.del(`/v1/admin/lottery/${id}`);
};

export const getLotterySetSummaryByPeriodIdApi = async (
  periodId: string
): Promise<AxiosResponse<ILotterySetSummary>> => {
  return await http.get(`/v1/admin/lottery-set/summary/${periodId}`);
};

export const createSetByPeriodIdApi = async (
  id: string
): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/admin/lottery/create-set/${id}`, null);
};

export const getLatestLottery = async (): Promise<AxiosResponse<any>> => {
  return await http.get(`/v1/admin/latest-lottery`);
};
