import { AxiosResponse } from "axios";
import { IBankAccount, IBankAccountRequest, ICreateBankAccountRequest } from "../interface/bank-account";
import { http } from "../utils/http";

export const getBankAccountByUserIdApi = async (uid: string): Promise<AxiosResponse<IBankAccount[] | null>> => {
  return await http.get(`/v1/admin/accounts/${uid}`)
};

export const updateBankAccountByIdApi = async (id: string, payload: IBankAccountRequest): Promise<void> => {
  return await http.put(`/v1/admin/accounts/${id}`, payload)
};
export const createBankAccountByIdApi = async (payload: ICreateBankAccountRequest): Promise<void> => {
  return await http.post(`/v1/admin/accounts`, payload)
};