import FileSaver from "file-saver";
import moment from "moment";
import { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { filterWalletsApi } from "../../api/wallet";
import Breadcrumb from "../../component/breadcrumb";
import Pagination from "../../component/pagination";
import Meta from "../../interface/meta";
import { IWallet } from "../../interface/wallet";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";

const Report = () => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(false);
  const [wallets, setWallets] = useState<IWallet[]>([]);
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [filter, setFilter] = useState({
    begin: 0,
    end: 0,
    userName: "",
    isOrderLottery: "",
  })
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);


  const filterHistoryDeposit = async (page: number, limit: number) => {
    try {
      const { data } = await filterWalletsApi({
        type: "1",
        userName: !filter.userName ? undefined : filter.userName,
        isOrderLottery: !filter.isOrderLottery ? undefined : filter.isOrderLottery,
        begin: filter.begin !== 0 ? moment.unix(filter.begin).utc().unix() : 0,
        end: filter.end !== 0 ? moment.unix(filter.end).utc().unix() : 0,
        page,
        limit,
      });

      setWallets(data.results ?? [])
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const exportCsv = async () => {
    try {
      setLoading(true);
      const response = await filterWalletsApi({
        begin: filter.begin !== 0 ? moment.unix(filter.begin).utc().unix() : 0,
        end: filter.end !== 0 ? moment.unix(filter.end).utc().unix() : 0,
        isOrderLottery: !filter.isOrderLottery ? undefined : filter.isOrderLottery,
        userName: !filter.userName ? undefined : filter.userName,
        type: "1",
        format: "csv",
      });

      let BOM = "\uFEFF";
      let csvData = BOM + response.data as any;
      let blob = new Blob([csvData], {
        type: 'text/csv;charset=utf-8',
      });

      FileSaver.saveAs(blob, `report.csv`);

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    filterHistoryDeposit(1, meta.limit)
    // eslint-disable-next-line
  }, [filter])

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <Breadcrumb
        title={"รายงาน"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "รายงาน" }
        ]}
      />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <span className="mr-2 ">ทั้งหมด {meta.totalCount} รายการ</span>
                </div>
                <div className="col-lg p-0">
                  <div className="form-row mb-2 ">
                    <div className="col-md mb-1">
                      <select
                        className="form-control"
                        value={filter.isOrderLottery}
                        onChange={(e) => {
                          setFilter(prev => ({
                            ...prev,
                            isOrderLottery: e.target.value,
                          }))
                        }}
                      >
                        <option value="">ทั้งหมด</option>
                        <option value="true">ยอดขาย</option>
                        <option value="false">สินค้าอัพเกรด</option>
                      </select>
                    </div>
                    <div className="col-md mb-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="ชื่อสมาชิก"
                        value={filter.userName}
                        onChange={(e) => {
                          setFilter(prev => ({
                            ...prev,
                            userName: e.target.value,
                          }))
                        }}
                      />
                    </div>
                    <div className="col-md mb-1">
                      <ReactDatePicker
                        selected={startDate}
                        onChange={(dates: [Date | null, Date | null]) => {
                          const [start, end] = dates;
                          setStartDate(start);
                          setEndDate(end);
                          if ((!!start && !!end) || (!start && !end)) {
                            setFilter(prev => ({
                              ...prev,
                              begin: (start?.valueOf() ?? 0) / 1000,
                              end: (end?.valueOf() ?? 0) / 1000,
                            }))
                          }
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        dateFormat={"dd/MM/yyyy"}
                        placeholderText="วันที่ จาก - ถึง"
                        isClearable={true}
                        customInput={
                          <input className="form-control" />
                        }
                      />
                    </div>
                    <div className="col-auto mr-2">
                      <button onClick={() => { exportCsv() }} className="btn btn-outline-primary">Export</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="table-responsive">
                  <table id="dataTable" className="table">
                    <thead>
                      <tr>
                        <th>ออเดอร์</th>
                        <th>วันเวลา</th>
                        <th>User</th>
                        <th>รายได้</th>
                        <th>ยอด</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        wallets.length > 0 ? (
                          wallets.map(e => {
                            return (
                              <tr key={e.id}>
                                <td>
                                  <div className='hover-underline cursor-pointer' onClick={() => {
                                    if (e.orderId !== "") {
                                      navigate(`${process.env.PUBLIC_URL}/orders/${e.orderId}`)
                                    } else if (e.orderUpgradeId !== "") {
                                      navigate(`${process.env.PUBLIC_URL}/products-upgrade/orders/${e.orderUpgradeId}`)
                                    }
                                  }}>{e.orderNo}</div>
                                </td>
                                <td>
                                  {moment(e.createdAt).format("DD/MM/yyyy HH:mm:ss")}
                                </td>
                                <td>
                                  <div className='hover-underline cursor-pointer' onClick={() => navigate(`${process.env.PUBLIC_URL}/users/edit/${e.userId}`)}>{e.user?.firstName} {e.user?.lastName}</div>
                                  <span className="tx-12 text-muted">{e.user?.phoneNumber}</span>
                                </td>
                                <td>
                                  {
                                    (e.orderId !== "") ? "ยอดขาย" : "สินค้าอัพเกรด"
                                  }
                                </td>
                                <td>
                                  {e.amount.toLocaleString("en-US")}
                                </td>
                              </tr>
                            )
                          })
                        ) : (
                          <>
                            <tr>
                              <td colSpan={5}>
                                <div className="text-center">ไม่พบรายการ</div>
                              </td>
                            </tr>
                          </>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <Pagination
                active={meta.page}
                limit={meta.limit}
                onPageChange={(page) => {
                  filterHistoryDeposit(page, meta.limit);
                }}
                pageRangeDisplayed={5}
                total={meta.totalCount}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Report;
