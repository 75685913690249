import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRule } from '../interface/rule';
import { IUser } from '../interface/user';
import { RootState } from './store';

export interface AuthState {
  user: IUser,
  rules: IRule,
}

const initialState: AuthState = {
  user: {
    id: "",
    userNo: 0,
    firstName: "",
    lastName: "",
    credit: 0,
    status: 1,
    picture: "",
    email: "",
    phoneNumber: "",
    birthDate: "",
    role: "",
    line: "",
    facebook: "",
    lineId: "",
    appleId: "",
    idCardNo: "",
    isDealer: false,
    dealerApprovedAt: null,
    dealerApprovedBy: "",
    referralCode: "",
    referralBy: "",
    isNewMember: false,
    createdAt: "",
    createdBy: "",
    updatedAt: "",
    updatedBy: ""
  },
  rules: {
    dashboard: false,
    userMember: false,
    order: false,
    productsUpgrade: false,
    report: false,
    withdraw: false,
    lottery: false,
    news: false,
    banner: false,
    affiliate: false,
    setting: false,
  }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setRules: (state, action: PayloadAction<IRule>) => {
      state.rules = action.payload;
    },
  },
});

export const { setProfile, setRules } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
