import { useState, useEffect } from "react";
import { getAffiliateApi, updateAffiliateApi } from "../../api/core";
import Breadcrumb from "../../component/breadcrumb";
import { IAffiliate } from "../../interface/affiliate";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { getSrcImage } from "../../utils/utils";

const $ = require('jquery');
$.dropify = require("dropify");

const Affiliate = () => {
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(false);
  const [affiliate, setAffiliate] = useState<IAffiliate>({} as IAffiliate);
  const maxWidth = 1200;
  const maxHeight = 630;
  const initDropify = (defaultFile?: string) => {
    const drEvent = $('.dropify').dropify({
      defaultFile: !!defaultFile ? getSrcImage(defaultFile) : "",
      error: {
        'minWidth': `จำกัดขนาดความกว้างของรูปที่ ${maxWidth}px`,
        'maxWidth': `จำกัดขนาดความกว้างของรูปที่ ${maxWidth}px`,
        'minHeight': `จำกัดขนาดความสูงของรูปที่ ${maxHeight}px`,
        'maxHeight': `จำกัดขนาดความสูงของรูปที่ ${maxHeight}px`,
      }
    });
    drEvent.on('dropify.afterClear', function (event, element) {
      setAffiliate(prev => ({
        ...prev,
        logo: "",
      }))
    });
  }


  const getAffiliate = async () => {
    try {
      setLoading(true);

      const { data } = await getAffiliateApi();
      setAffiliate(data)

      initDropify(data.logo)

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const updateAffiliate = async (payload: IAffiliate) => {
    try {
      setLoading(true);

      const { data } = await updateAffiliateApi(payload);
      setAffiliate(data)

      setLoading(false);

      openSnackbar("Successfuly");
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    getAffiliate();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <Breadcrumb
        title={"ลิ้งแนะนำ"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "ลิ้งแนะนำ" }
        ]}
      />


      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="form-group row">
                <label htmlFor="" className="col-sm-3 col-form-label">รูป</label>
                <div className="col-sm-8">
                  <input
                    type="file"
                    onChange={(e) => {
                      let reader = new FileReader();
                      reader.readAsDataURL(e.target.files![0]);
                      reader.onload = function () {
                        setAffiliate(prev => ({
                          ...prev,
                          logo: reader.result as string,
                        }))
                      };
                    }}
                    className="dropify"
                    data-max-file-size="3M"
                    data-allowed-file-extensions="jpg png jpeg"
                    data-height="400"
                    data-max-height={maxHeight + 1}
                    data-min-height={maxHeight - 1}
                    data-max-width={maxWidth + 1}
                    data-min-width={maxWidth - 1}
                  />
                  <div className="text-danger text-small">* จำกัดขนาดความกว้างของรูปที่ {maxWidth}px</div>
                  <div className="text-danger text-small">* จำกัดขนาดความสูงของรูปที่ {maxHeight}px</div>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="" className="col-sm-3 col-form-label">หัวข้อ</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="หัวข้อ"
                    value={affiliate.title}
                    onChange={(e) => {
                      setAffiliate((prev) => ({
                        ...prev,
                        title: e.target.value
                      }))
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="" className="col-sm-3 col-form-label">รายละเอียด</label>
                <div className="col-sm-8">
                  <textarea
                    className="form-control"
                    style={{
                      maxWidth: "100%",
                      minHeight: 150,
                    }}
                    placeholder="รายละเอียด"
                    value={affiliate.description}
                    onChange={(e) => {
                      setAffiliate((prev) => ({
                        ...prev,
                        description: e.target.value
                      }))
                    }}
                  />
                </div>
              </div>
              <div className="col-12 border-top pt-4">
                <div className="row">
                  <button className="btn btn-primary ml-auto mr-2" onClick={() => {
                    updateAffiliate(affiliate)
                  }}>บันทึก</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Affiliate;
